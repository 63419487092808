/* --------------------------------------------------------------------------------
 * Copyright: Altair Engineering, Inc., 2020.  All rights reserved.
 * Contains trade secrets of Altair Engineering, Inc.
 * Copyright notice does not imply publication.
 * Decompilation or disassembly of this software is strictly prohibited.
 * --------------------------------------------------------------------------------*/
import React, { useEffect, useCallback, useRef, useState, useMemo } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { useHistory } from 'react-router-dom';

import { ScrollablePane } from '@fluentui/react/lib/ScrollablePane';

import { Content, Main, Wrap, Margin5 } from './styles';
import * as actions from '../actions';

import { useDebounce } from 'react-use';

import Landing from '../components/Landing';
import NoResults from '../components/NoResults/index.js';

import { FilterButton } from '../components/FilterButton';

import TileView from '../components/TileView';
import Datasheet from '../components/Datasheet';
import { NoLicense } from '../components/NoLicense';

import '../components/buildingBlockStyles.css';

import ContentDetails, { Tab } from '../gooey/gql-components/__experimental/ContentDetails';
import InfiniteScroll from '../components/InfiniteScroll';

import Panel from '../components/Panel';
import { CommandBar, IconButton, ContextualMenu, Dropdown } from '@fluentui/react';
import ImportModal from '../components/ImportModal';
import ExportModal from '../components/ExportModal';

import { initializeIcons } from '@fluentui/react/lib/Icons';
import ComparisonTable from '../components/ComparisonTable';
import PlotComparison from '../components/PlotComparison';
import CompareOverlay from '../components/CompareOverlay';
import AdvancedSearch, { FAVORITE_FORMAT } from '../components/AdvancedSearch';

import MBaseLink from '../components/MBaseLink';
import { isEmpty, isEqual } from 'lodash';

import ContactUsDialog from '../gooey/api-components/dialogs/ContactUsDialog';
import NoDriveLicenseDialog from '../components/dialogs/NoDriveLicenseDialog';

import UpgradeDialog from '../components/dialogs/UpgradeDialog';
import LimitSelectionDialog from '../components/dialogs/LimitSelectionDialog';
import LimitFavoritesDialog from '../components/dialogs/LimitFavoritesDialog';
import UserPreferencesDialog from '../components/dialogs/UserPreferencesDialog';
import CustomizeTablePanel from '../components/CustomizeTablePanel';
import Cookies from 'universal-cookie';

import './search.css';
import './common.css';
import createFilterTags from './data/filterTags.js';
import { createContextMenuProps } from '../gooey/components/__experimental/EntityList/utils/createContextMenuProps';

import { useAppFrame } from '../amdc-app-frame/context/useAppFrame.js';
import { useLicense, LicenseState } from '../common/hooks/useLicense';
import { getApi } from '../api';

import * as TextMapping from '../utils/textMapping';

import BuildingBlockList from '../components/BuildingBlockList';
import axios from 'axios';
import ScatterPlot from '../components/ScatterPlot';
import PropertySelectionTree from '../components/PropertySelectionTree';
import * as FullStory from '@fullstory/browser';
import ResultSummary from '../components/ResultSummary';
import AppTitle from '../components/AppTitle';
import BBPageDialog from '../components/dialogs/BBPageDialog';
import LCAGeneric from '../components/LCAGeneric';
import LocalNotifications from '../components/localNotifications';
import TopbarContainer from '../components/TopbarContainer';

import { withTopbar } from '../topbar/context/withTopbar.js';

import WaffleMenuContainer from '../components/WaffleMenuContainer';
import { Matomo } from '../utils/Matomo';
import Panels from '../topbar/components/panels';
import LeftSidePanel from '../components/LeftSidePanel';
import { useWindowSize } from 'react-use';
import { MessageBar, MessageBarType } from '@fluentui/react';
import SaveCriteriaDialog from '../components/gooey/SearchForm/Dialogs/SaveCriteriaDialog';
import SaveCriteriaAsDialog from '../components/gooey/SearchForm/Dialogs/SaveCriteriaAsDialog';
import LoadCriteriaDialog from '../components/gooey/SearchForm/Dialogs/LoadCriteriaDialog';

import { FORMAT } from '../components/AdvancedSearch';

import get from 'lodash/get';
import BBPageComponent from '../components/BBPageComponent';
import { Helmet } from 'react-helmet';

import { setupWebSocketConnection } from '../websocket';
import RoundIconButton from '../gooey/components/RoundIconButton/index.js';
import AccountPanelContainer from '../components/AccountPanelContainer/index.js';
import { Spinner, SpinnerSize } from '@fluentui/react';

const Path = require('path');

initializeIcons('/fluent-icons/');

const cookies = new Cookies();

const mapStateToProps = (state) => {
  return {
    loggedInUser: state.loggedInUser,
    roots: state.roots,
    selectedEntities: state.selectedEntities,
    localNotification: state.localNotifications,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(actions, dispatch);
};

function convertObjecttoQs(qs) {
  const params = new URLSearchParams();
  Object.keys(qs).forEach((key) => {
    params.append(key, qs[key]);
  });

  const query = params.toString();
  return query ? `?${query}` : '';
}

function View(props) {
  const {
    roots,
    getAppContent,
    importCheck,
    getMaterials,
    getSearchCatalog,
    getUnits,
    getCompareCatalog,
    getTableColumns,
    getMaterialDatasheet,
    getCompareDetails,
    getTexts,
    getUserPreferences,
    saveUserPreferences,
    getScatterData,
    toggleImportModal,
    toggleExportModal,
    importMaterials,
    downloadMaterialCard,
    downloadPDF,
    exportMaterials,
    qtExportMaterials,
    qtHandler,
    loggedInUserEntity,
    replaceLoggedInUser,
    showFullPageLoader,
    updateOffset,
    updateLoadIndex,
    cleanEntityItems,
    setEntityView,
    fetchSearch,
    replaceFilterData,
    clearSearch,
    getPolarData,
    getBuildingBlockCompareDetails,
    getLandingBuildingBlocks,
    getResultSummary,
    getLCAGeneric,
    getBBPageForDialog,
    getBBPageForComponent,
    clearBBPageForDialog,
    getAppTitle,
    getPolarCatalog,
    getScatterCatalog,
    getImpactAssessmentData,
    downloadXLS,
    checkAppAccess,
    exportCompareExcel,
    localNotification,
    hideLocalNotification,
    replacePanel,
    panelId,
    setFavoriteList,
    favoriteAction,
    setFavoriteView,
    setSimilarId,
    setSimilarProfile,
    getNotifications,
    dismissNotification,
    setUserProfile,
    setAccountPanelProps,
    setPluginSource,
    theme,
    setTheme,
    setApplicationContent,
  } = props;
  const {
    items,
    appContent,
    config,
    tags,
    texts,
    preferences,
    language,
    availLanguages,
    isImportModalOpened,
    isExportModalOpened,
    providers,
    unitSystems,
    userUnitSystems,
    unitSystem,
    canAccess,
    uicheckRun,
    perms,
    materialDatasheet,
    compareDetails,
    contentDefinitions,
    catalogs,
    searchCatalog,
    ranges,
    advSearchRanges,
    compareCatalog,
    tableColumns,
    derivedContentDefinitions,
    supportedApplicationMenus,
    offset,
    hasMore,
    firstLoad,
    pageSize,
    entityView,
    pdfDownloadWaiting,
    filterDataStatus,
    scatterData,
    polarData,
    skipLanding,
    bbCompareDetails,
    landingBB,
    polarLoadingStatus,
    polarCatalog,
    scatterCatalog,
    defaultViewShown,
    resultSummary,
    lcaGeneric,
    bbPageForDialog,
    bbPageForComponent,
    appTitle,
    hasSearchCriteria,
    track,
    languageOverride,
    activeId,
    favoriteList,
    favoriteView,
    similarId,
    similarProfile,
    notifications,
    datasheetStatus,
    rangeStatus,
  } = roots;

  const history = useHistory();
  //Testing Fullstory integration on dev - will update/correct below snippet once test done.
  if (appContent && appContent.config && appContent.config.fullstory && appContent.config.fullstory.orgId && !FullStory.isInitialized()) {
    FullStory.init(appContent.config.fullstory);
    FullStory.setVars('page', {
      pageName: 'Main',
      email: (loggedInUserEntity && loggedInUserEntity.id) ?? 'MaterialsFreeUser',
      licensed: canAccess,
    });
  }

  function addStyle(id, style) {
    if (!addedStyles.includes(id)) {
      var styleSheet = document.createElement('style');

      let styleText = '';
      if (style && style.length > 0) {
        for (let styleElement of style) {
          styleText += styleElement + ' ';
        }
        styleSheet.innerText = styleText;

        document.head.appendChild(styleSheet);
      }

      setAddedStyles((prevStyles) => [...prevStyles, id]);
    }
  }

  const { width } = useWindowSize();
  const isMobile = width < 640;

  let qs = useRef(getSearchParams(history));

  const [isCacheInitialized, setIsCacheInitialized] = useState(false);
  const [isLeftPanelOpen, setIsLeftPanelOpen] = useState(false);

  const { setWordmark, setWaffleMenuProps, setNotifications, setDismissNotification, setTexts, setAppContent, onlineHelpUrl } =
    useAppFrame();

  const [filteredTags, setFilteredTags] = useState([]);
  const [quickSearchValue, setQuickSearchValue] = useState('');

  const [webSocket, setWebSocket] = useState(null);

  const [isUserPreferencesDialogHidden, setUserPreferencesDialogHidden] = useState(true);
  const [isCustomizePanelHidden, setCustomizePanelHidden] = useState(true);

  const [sortColumn, setSortColumn] = useState('name');
  const [sortDirection, setSortDirection] = useState('ASC');

  const [activeEntity, setActiveEntity] = useState('');
  const [compareIds, setCompareIds] = useState([]);
  const [selectionLimit, setSelectionLimit] = useState();
  const [favoriteLimit, setFavoriteLimit] = useState();

  const [defaultTitle, setDefaultTitle] = useState('Altair Material Data Center');
  const [matomo, setMatomo] = useState(null);
  const [foo, setFoo] = useState(null);
  const [currentPage, setCurrentPage] = useState(null);
  const [currentTool, setCurrentTool] = useState(null);
  const [shareMessage, setShareMessage] = useState(null);
  const [addedStyles, setAddedStyles] = useState([]);
  const [isSaveFavoritesAsDialogHidden, setIsSaveFavoritesAsDialogHidden] = useState(true);
  const [isSaveFavoritesDialogHidden, setIsSaveFavoritesDialogHidden] = useState(true);
  const [isLoadFavoritesDialogHidden, setIsLoadFavoritesDialogHidden] = useState(true);
  const [savedFavorites, setSavedFavorites] = useState([]);
  const [savedFavoriteNames, setSavedFavoriteNames] = useState([]);
  const [comparePermission, setComparePermission] = useState('ui.compare');
  const [scatterPlotPermission, setScatterPlotPermission] = useState('ui.scatterplot');
  const [compareRestrictedPage, setCompareRestrictedPage] = useState('');
  const [scatterPlotRestrictedPage, setScatterPlotRestrictedPage] = useState('');
  const [compareTitle, setCompareTitle] = useState('Compare');
  const [currentFavoriteId, setCurrentFavoriteId] = useState(null);
  const [fetchedFavorites, setFetchedFavorites] = useState(false);
  const [datasheetHistory, setDatasheetHistory] = useState([]);
  const [sentHandshake, setSentHandshake] = useState(false);
  const [ucEvent, setUcEvent] = useState();

  const { checkout, state: licenseState } = useLicense({ loggedInUser: loggedInUserEntity, config: config });

  let api = getApi();

  const onBackButtonEvent = () => {
    // check for presence of product in the URL
    let regex = /datasheet\/([^/]+)/;
    let product = document.location.pathname.match(regex);
    let productName = '';

    if (product && product.length > 1) {
      productName = product[1];
    }

    if (productName) {
      let entity = { id: decodeURIComponent(productName).replace(/\$p\$/g, '%') };
      qs.current.pattern = decodeURIComponent(productName).replace(/\$p\$/g, '%').replace(/[\s]*/g, '');
      onEntityInvoke(entity, { push: false });
    }
  };

  useEffect(() => {
    //window.history.pushState(null, null, window.location.pathname);
    window.addEventListener('popstate', onBackButtonEvent);
    return () => {
      window.removeEventListener('popstate', onBackButtonEvent);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setTexts(texts);
  }, [texts, setTexts]);

  useEffect(() => {
    setAppContent(appContent);
    setApplicationContent(appContent);
    if (appContent && appContent.user) {
      setUserProfile(appContent.user);
    }
  }, [appContent, setAppContent, setUserProfile, setApplicationContent]);

  useEffect(() => {
    if (window.opener && appContent && !sentHandshake) {
      window.opener.postMessage({ type: 'handshake' }, '*');
      setSentHandshake(true);
    }
  }, [appContent, sentHandshake]);

  useEffect(() => {
    if (appContent && appContent.config && appContent.config.pluginsources) {
      const listener = (event) => {
        // Do we trust the sender of this message?
        if (!appContent.config.pluginsources.includes(event.origin)) return;

        if (event.data.type === 'handshake') {
          setPluginSource(event.origin);
        }
      };

      window.addEventListener('message', listener);

      return () => {
        window.removeEventListener('message', listener);
      };
    }
  }, [setPluginSource, appContent]);

  useEffect(() => {
    setNotifications(notifications);
  }, [notifications, setNotifications]);

  useEffect(() => {
    setDismissNotification(dismissNotification);
  }, [dismissNotification, setDismissNotification]);

  const notifyShared = useCallback(() => {
    setShareMessage(TextMapping.getUIText(TextMapping.UI_TEXT_LINK_COPIED_TO_CLIPBOARD, texts));
    setTimeout(() => {
      setShareMessage(null);
    }, 3000);
  }, [texts]);

  function closeNotification() {
    setShareMessage(null);
  }

  useEffect(() => {
    const unlisten = history.listen(() => {
      setFoo([]);
    });

    return unlisten;
  }, [history]);

  useEffect(() => {
    if (matomo && foo) {
      matomo.trackPageView(history.location.pathname);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [foo, matomo, history]);

  /*   useEffect(() => {
    if (appContent) {
      let savedFavorites = JSON.parse(localStorage.getItem(appContent.shortname + '-savedFavorites') || '[]');
      setSavedFavorites(savedFavorites);
    }
  }, [appContent]); */

  /*   useEffect(() => {
    if (appContent) {
      localStorage.setItem(appContent.shortname + '-savedFavorites', JSON.stringify(savedFavorites));
    }
    let names = [];
    for (let savedFavorite of savedFavorites) {
      names.push(savedFavorite.name);
    }
    setSavedFavoriteNames(names);
  }, [savedFavorites, appContent]); */

  useEffect(() => {
    const compareConfig =
      compareCatalog && compareCatalog.length && compareCatalog[0].node && compareCatalog[0].node.config
        ? JSON.parse(compareCatalog[0].node.config)
        : null;
    setSelectionLimit((compareConfig && compareConfig.maxcount) || 10);
    if (compareConfig && compareConfig.restricted && compareConfig.restricted.permission) {
      setComparePermission(compareConfig.restricted.permission);
    }

    if (compareConfig && compareConfig.restricted && compareConfig.restricted.page) {
      setCompareRestrictedPage(compareConfig.restricted.page);
    }

    if (compareCatalog && compareCatalog.length && compareCatalog[0].name) {
      setCompareTitle(compareCatalog[0].name);
    }
  }, [compareCatalog]);

  React.useEffect(() => {
    if (config && !isEmpty(config)) {
      let waffleMenuProps = {
        additionalServicesProps: {
          services: config.wafflemenuservices
            ? config?.wafflemenuservices
                ?.map((service) => {
                  if (service.content) {
                    return {
                      isOpen: !service.collapsed,
                      serviceName: service.name,
                      serviceLinks: service.content.map((link) => {
                        return {
                          title: link.title,
                          url: link.url,
                          action: link.action,
                          target: link.target,
                        };
                      }),
                    };
                  } else {
                    return null;
                  }
                })
                .filter((service) => service !== null)
            : [],
        },
      };

      waffleMenuProps.accountPanelServices = {
        services: config.accountpanelservices
          ? config?.accountpanelservices
              ?.map((service) => {
                if (service.content) {
                  return {
                    isOpen: !service.collapsed,
                    serviceName: service.name,
                    serviceLinks: service.content.map((link) => {
                      return {
                        title: link.title,
                        url: link.url,
                        action: link.action,
                        target: link.target,
                      };
                    }),
                  };
                } else {
                  return null;
                }
              })
              .filter((service) => service !== null)
          : [],
      };

      let url = '/';
      if (config && config.defaultview && config.defaultview.url) {
        url = config.defaultview.url;
      }

      waffleMenuProps.homeButtonProps = config.homebutton ? config.homebutton : { url: url };
      waffleMenuProps.onlineHelpUrl = onlineHelpUrl;

      if (config.wafflemenuinjection) {
        const injection = (
          <BBPageComponent
            page={config.wafflemenuinjection.page}
            bbPageForComponent={bbPageForComponent}
            getBBPageForComponent={getBBPageForComponent}
            appContent={appContent}
            language={language}
            texts={texts}
          />
        );

        waffleMenuProps.injection = injection;
      }

      if (config.accountpanelinjection) {
        const injection = (
          <BBPageComponent
            page={config.accountpanelinjection.page}
            bbPageForComponent={bbPageForComponent}
            getBBPageForComponent={getBBPageForComponent}
            appContent={appContent}
            language={language}
            texts={texts}
          />
        );

        waffleMenuProps.accountPanelInjection = injection;
      }

      setWaffleMenuProps(waffleMenuProps);
    }
  }, [
    setAccountPanelProps,
    setWaffleMenuProps,
    roots.canImport,
    config,
    texts,
    onlineHelpUrl,
    bbPageForComponent,
    getBBPageForComponent,
    language,
    appContent,
  ]);

  useEffect(() => {
    if (config && config.defaultview && preferences && !preferences.skipLanding) {
      if (!preferences.skipLanding && !defaultViewShown) {
        if (
          !history.location.pathname.includes('datasheet') &&
          !history.location.pathname.includes('page') &&
          !history.location.pathname.includes('toolbox') &&
          !history.location.search.includes('favorites')
        ) {
          history.push(config.defaultview.url);
        }
        sessionStorage.setItem(appContent.shortname + '-defaultViewShown', true);
        getTexts();
      }
    } else if (config && config.defaultview && preferences && preferences.skipLanding) {
      sessionStorage.setItem(appContent.shortname + '-defaultViewShown', true);
    }
  }, [config, skipLanding, preferences, history, defaultViewShown, showFullPageLoader, getTexts, appContent]);

  useEffect(() => {
    if (config && config.scatterplot && config.scatterplot.restricted) {
      setScatterPlotPermission(config.scatterplot.restricted.permission);
      setScatterPlotRestrictedPage(config.scatterplot.restricted.page);
    }
  }, [config]);

  useEffect(() => {
    // Add meta information from config
    if (config && config.meta && config.meta.length) {
      for (let entry of config.meta) {
        let meta = document.head.querySelector(`meta[name="${entry.name}"]`);
        if (meta) {
          let existingMeta = document.head.querySelector(`meta[name="${entry.name}"]`);
          existingMeta.content = entry.content;
        }

        if (!meta) {
          meta = document.createElement('meta');
          meta.name = entry.name;
          meta.content = entry.content;
          document.getElementsByTagName('head')[0].prepend(meta);
        }
      }
    }
  }, [config]);
  useEffect(() => {
    if (!isEmpty(texts)) {
      if (
        (appContent.user && appContent.user.licenseType === 'named' && licenseState === LicenseState.Loading) ||
        licenseState === LicenseState.Initializing
      ) {
        showFullPageLoader(true, 'Loading');
      } else if (licenseState === LicenseState.CheckingOut) {
        showFullPageLoader(true, 'Checkout Altair Tokens');
      } else {
        showFullPageLoader(false);
      }
    } else {
      axios.interceptors.response.use(
        function (response) {
          // Any status code that lie within the range of 2xx cause this function to trigger
          return response;
        },
        function (error) {
          // Any status codes that falls outside the range of 2xx cause this function to trigger
          if (error.response.status === 401) {
            let redirectPath = '/login';
            if (!isEmpty(history.location.pathname) && history.location.pathname !== '/') {
              redirectPath += '?redirectUri=' + history.location.pathname;
            }
            if (!isEmpty(history.location.search)) {
              redirectPath += history.location.search;
            }
            document.location.href = redirectPath;
          }
          return Promise.reject(error);
        }
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [licenseState, showFullPageLoader, texts]);

  useEffect(() => {
    if (appContent) {
      localStorage.setItem(appContent.shortname + '-entityView', entityView);
    }
  }, [entityView, appContent]);
  useEffect(() => {
    if (tags && tags.length > 0) {
      setFilteredTags(JSON.parse(JSON.stringify(tags)));
    }
  }, [tags]);

  useEffect(() => {
    if (appContent !== null && roots.hasMaterials === false) {
      if (appContent.success !== false && appContent.user) {
        const userData = cookies.get('userData');

        if (userData && appContent.user.username !== userData.username) {
          cookies.remove('userData');
          window.location.href = '/login';
        } else {
          if (licenseState === LicenseState.Loading && appContent.user.licenseType === 'named') {
            checkout();
          } else if (appContent.user.licenseType !== 'named') {
            axios.defaults.headers.common['skipLicenseCache'] = false;
          }
          getUserPreferences();
          importCheck();
        }
      } else {
        showFullPageLoader(false);
      }
    }
  }, [appContent, roots.hasMaterials, getUserPreferences, importCheck, history, showFullPageLoader, licenseState, checkout]);

  React.useEffect(() => {
    let appTitles = [];

    if (config.apptitle) {
      if (config.apptitle.blocks && config.apptitle.blocks.length > 0) {
        for (let block of config.apptitle.blocks) {
          appTitles.push(
            <AppTitle
              name={block.name}
              appContent={appContent}
              config={config}
              texts={texts}
              canAccess={canAccess}
              onDownloadClick={onDownloadClick}
              handleContactUsClick={handleContactUsClick}
              webSocket={webSocket}
              appTitle={appTitle}
              getAppTitle={getAppTitle}
              language={language}
              ranges={ranges}
            />
          );
        }
      }
    }

    if (!isEmpty(config) && !config.topbar) {
      let license = appContent.user && appContent.user.license && !isEmpty(appContent.user.license) ? appContent.user.license[0] : '';
      let username = appContent.user && appContent.user.username ? appContent.user.username : '';
      if (!license) license = username === 'freeuser' ? 'freeversion' : 'nolicense';
      let licensename = TextMapping.getUIText(license, texts, null, '');
      let apptitlelicname = licensename
        ? TextMapping.getUIText(TextMapping.UI_TEXT_LICENSENAME, texts, { licensename: licensename }, '')
        : '';
      let subtitle = config.subtitle && config.subtitle.uitext ? ' - ' + TextMapping.getUIText(config.subtitle.uitext, texts) : '';

      if (!isEmpty(appTitles)) {
        if (subtitle) appTitles.push(subtitle);
        if (apptitlelicname) appTitles.push(apptitlelicname);
        setWordmark(<div style={{ display: 'flex', justifyContent: 'center', fontWeight: 'bold', gap: '5px' }}>{appTitles}</div>);
      } else {
        setWordmark(
          <div style={{ display: 'flex', justifyContent: 'center', fontWeight: 'bold' }}>
            {config.defaultview && config.defaultview.url && (
              <a href={config.defaultview.url}>
                <img
                  className="wordmark-image"
                  alt="Altair Material Data Center"
                  src="/wordmark/logo_MaterialDataCenter.svg"
                  height="20px"
                />
              </a>
            )}
            {!(config.defaultview && config.defaultview.url) && (
              <img className="wordmark-image" alt="Altair Material Data Center" src="/wordmark/logo_MaterialDataCenter.svg" height="20px" />
            )}
            {subtitle}
            {apptitlelicname}
          </div>
        );
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setWordmark, texts, config, appTitle]);

  useEffect(() => {
    if (config.favoritelimit) {
      setFavoriteLimit(config.favoritelimit);
    } else {
      setFavoriteLimit(50);
    }
  }, [config]);

  useEffect(() => {
    showFullPageLoader(true);
    /* Populate cache. */
    const _populateCache = async () => {
      let pathParts = history.location.pathname.split('/');
      let name = null;
      let specifiedLanguage = null;
      let index = 1;
      let reservedWords = ['datasheet', 'page', 'toolbox', 'auth'];

      if (pathParts.length > 1 && pathParts[1].length === 2) {
        // language
        specifiedLanguage = pathParts[1];
        index++;
      }

      let limit = pathParts.length;
      if (index + 2 < pathParts.length) {
        limit = index + 2;
      }

      for (let i = index; i < limit; i++) {
        if (pathParts[i].length > 0) {
          if (!reservedWords.includes(pathParts[i])) {
            if (!name) {
              name = pathParts[i];
            } else {
              axios.defaults.headers.common['searchprofile'] = pathParts[i];
            }
          } else {
            break;
          }
        }
      }
      getAppContent(name, specifiedLanguage);

      setIsCacheInitialized(true);
    };

    if (licenseState === LicenseState.Loading) {
      _populateCache();
      // check if the websocket is available to prevent recreating
      let websocketclient = sessionStorage.getItem('websocketclient');
      if (!websocketclient) {
        websocketclient = setupWebSocketConnection({ appContent, history, api });
      }

      // store websocket client in session if it is available, else it stores null as string which creates problem
      if (websocketclient) {
        sessionStorage.setItem('websocketclient', websocketclient);
        setWebSocket(websocketclient);
      }

      // close and remove the websocket connection
      window.onbeforeunload = function () {
        if (websocketclient || sessionStorage.getItem('websocketclient')) {
          websocketclient.close();
          sessionStorage.removeItem('websocketclient');
          setWebSocket(null);
        }
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loggedInUserEntity, showFullPageLoader, licenseState]);

  // notify the plugin about the login state
  // if a valid non-guest user is logged in or not
  useEffect(() => {
    if (webSocket && webSocket.readyState > 1 && appContent && appContent.user) {
      webSocket.send(
        JSON.stringify({
          action: 'login',
          success: true,
          data: { user: appContent.user.email, email: appContent.user.email },
        })
      );
    }
  }, [appContent, webSocket]);

  useEffect(() => {
    if (
      !canAccess &&
      uicheckRun &&
      config &&
      config.upgradepremium &&
      !document.cookie.split('; ').find((row) => row.startsWith('welcomeDisplayed')) &&
      !history.location.pathname.includes('datasheet')
    ) {
      //setIsUpgradeDialogHidden(false);
      openBBPageModal('page=upgrade');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [canAccess, uicheckRun, texts, config, appTitle]);

  useEffect(() => {
    if (config.favicon) {
      let favicon = document.querySelector('[rel*=icon]');
      if (favicon && config.favicon.favicon) favicon.href = config.favicon.favicon;
      if (config.favicon.title && config.favicon.title.length) {
        document.title = config.favicon.title;
        setDefaultTitle(config.favicon.title);
      } else {
        document.title = defaultTitle;
      }
    } else if (!isEmpty(config)) {
      document.title = defaultTitle;
    }
    if (roots.config && (roots.config.driveconfig || roots.config.driveConfig)) {
      // call checkAppAccess
      checkAppAccess('Drive');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [config]);

  function getSearchParams(history) {
    const urlParams = new URLSearchParams(history.location.search);
    const obj = {};

    [...urlParams.entries()].forEach(([key, value]) => {
      obj[key] = value;
    });
    return obj;
  }

  useEffect(() => {
    qs.current = getSearchParams(history);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [history.location.search]);

  useEffect(() => {
    if (roots.status === 'APP_CONTENT_FAIL') {
      throw new Error('Unauthorized');
    }
  }, [roots.status]);

  useEffect(() => {
    let gcm = JSON.parse(localStorage.getItem('uc_gcm') || '{}');
    if (appContent && appContent.config && appContent.config.matomo && gcm && gcm.analyticsStorage && gcm.analyticsStorage === 'granted') {
      let mtm = appContent.config.matomo;

      let options = {
        id: mtm.id || '',
        url: mtm.url || '',
        tracker: mtm.tracker || '',
        //router,
        debug: mtm.debug || false,
      };

      let matomo = new Matomo(options);

      matomo.visit({
        license: appContent && appContent.tracking ? appContent.tracking.license : '',
        user: appContent && appContent.tracking ? appContent.tracking.user : '',
      });
      setMatomo(matomo);
    } else {
      setMatomo(null);
    }
  }, [appContent, ucEvent]);

  useEffect(() => {
    if (!roots.status && appContent && language && uicheckRun) {
      //getMaterialClassifications(qs.current, appContent);
      //getCatalogs(qs.current, appContent);

      let pageRegex = /page\/([^/]+)/;
      let page = history.location.pathname.match(pageRegex);
      let pageName = '';

      if (page && page.length > 1) {
        pageName = page[1];
        setCurrentPage(pageName);
      }

      if (appContent.config.style) {
        if (appContent.config.style.style && appContent.config.style.style.length) {
          addStyle(appContent.config.style.id, appContent.config.style.style);
        }
      }

      if (appContent.config.semanticcolors) {
        let style = [];

        for (const [themeName, values] of Object.entries(appContent.config.semanticcolors)) {
          for (const [key, value] of Object.entries(values)) {
            if (key.includes('Background')) {
              style.push(`.${themeName} .${key} { background-color: ${value}; }`);
            } else if (key.endsWith('Hovered')) {
              style.push(`.${themeName} .${key.replace('Hovered', '')}:hover { color: ${value}}`);
            } else if (key.includes('Visited')) {
              style.push(`.${themeName} .${key.replace('Visited', '')}:visited { color: ${value}}`);
            } else {
              style.push(`.${themeName} .${key} { color: ${value}; }`);
            }
          }
        }

        addStyle('semanticColors', style);
      }

      getSearchCatalog(qs.current, appContent);
      getUnits(qs.current, appContent);
      getCompareCatalog(qs.current, appContent);
      getTableColumns(qs.current, appContent);
      getPolarCatalog(qs.current, appContent);
      getScatterCatalog(qs.current, appContent);
      getImpactAssessmentData(qs.current, appContent);
      getNotifications();
      //getAvailLanguages(qs.current, appContent);
      if (activeEntity) {
        getMaterialDatasheet(activeEntity.id, qs.current, appContent);
      }

      if (compareIds) {
        getCompareDetails(compareIds, qs.current);
      }

      getTexts();
      handleSelectedEntitiesChange([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    roots.status,
    appContent,
    language,
    uicheckRun,
    qs,
    getSearchCatalog,
    getCompareCatalog,
    getTableColumns,
    getTexts,
    getPolarCatalog,
    getImpactAssessmentData,
    getNotifications,
  ]);

  /*
  useEffect(() => {
    if (searchCatalog) {
      getRanges(qs.current, searchCatalog, unitSystem, appContent);
    }
  }, [searchCatalog, getRanges, appContent, qs, unitSystem]);
*/

  useEffect(() => {}, [history]);

  useEffect(() => {
    if (searchCatalog && roots.hasMaterials === false && tableColumns && tableColumns.length > 0 && hasSearchCriteria) {
      if (
        !history.location.pathname.includes('toolbox') &&
        !history.location.pathname.includes('datasheet') &&
        !history.location.pathname.includes('page')
      ) {
        closePanel(false);
      }
      // check for presence of product in the URL
      let regex = /datasheet\/([^/]+)/;
      let product = document.location.pathname.match(regex);
      let productName = '';

      if (product && product.length > 1) {
        productName = product[1];
      }

      if (productName) {
        let entity = { id: decodeURIComponent(productName).replace(/\$p\$/g, '%') };
        qs.current.pattern = decodeURIComponent(productName).replace(/\$p\$/g, '%').replace(/[\s]*/g, '');
        onEntityInvoke(entity);
      }

      let toolRegex = /toolbox\/([^/]+)/;
      let tool = history.location.pathname.match(toolRegex);
      let toolName = '';

      if (tool && tool.length > 1) {
        toolName = tool[1];
        setCurrentTool(toolName);
      }

      let searchStr = convertObjecttoQs(qs.current);
      let url = history.location.pathname + searchStr;

      history.push(url);

      getMaterials(qs.current, sortColumn, sortDirection, entityView);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchCatalog, tableColumns, roots.hasMaterials, qs, sortColumn, sortDirection, entityView, getMaterials, hasSearchCriteria]);

  useEffect(() => {
    if (supportedApplicationMenus && supportedApplicationMenus.items) {
      for (let item of supportedApplicationMenus.items) {
        item['onClick'] = () => onCommandBarClick(item['key'], item['text']);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [supportedApplicationMenus]);

  useEffect(() => {
    if (appContent && appContent.user) {
      replaceLoggedInUser({ entity: appContent.user });
    }
  }, [appContent, replaceLoggedInUser]);

  const [selectedEntities, setSelectedEntities] = useState([]);

  const [searchVisible, setSearchVisible] = useState(false);

  const [advancedSearchVisible, setAdvancedSearchVisible] = useState(false);
  const [scatterPlotVisible, setScatterPlotVisible] = useState(false);
  const [co2CalcVisible, setCo2CalcVisible] = useState(false);
  const [detailsVisible, setDetailsVisible] = useState(false);
  const [compareVisible, setCompareVisible] = useState(false);

  const [importProvider, setImportProvider] = useState();
  const [importSourceTemplate, setImportSourceTemplate] = useState();

  const [exportUnitSystem, setExportUnitSystem] = useState();
  const [exportFlag, setExportFlag] = useState();
  const [file, setFile] = useState();
  const [compareButtonDisabled, setCompareButtonDisabled] = useState(true);

  const [detailsResize, setDetailsResize] = useState(false);
  const [compareResize, setCompareResize] = useState(false);
  const [compareLayout, setCompareLayout] = useState('row');

  const [compareFullScreen, setCompareFullScreen] = useState(false);

  const [providerCdefs, setProviderCdefs] = useState([]);
  const [activeCompareTab, setActiveCompareTab] = useState('Data');

  const [contextMenuProps, setContextMenuProps] = useState();

  const [scrollablePane, setScrollablePane] = useState();
  const scrollableContentRef = scrollablePane ? scrollablePane.contentContainer : undefined;

  const [isContactUsDialogHidden, setIsContactUsDialogHidden] = useState(true);
  const [isNoDriveLicenseDialogHidden, setNoDriveLicenseDialogHidden] = useState(true);
  const [isUpgradeDialogHidden, setIsUpgradeDialogHidden] = useState(true);
  const [isLimitSelectionDialogHidden, setIsLimitSelectionDialogHidden] = useState(true);
  const [isLimitFavoritesDialogHidden, setIsLimitFavoritesDialogHidden] = useState(true);

  const [isBBPageDialogHidden, setIsBBPageDialogHidden] = useState(true);
  const [bbPage, setBBPage] = useState();
  const [searchBreadcrumbs, setSearchBreadcrumbs] = useState([]);
  const [scatterUpdatedRanges, setScatterUpdatedRanges] = useState();
  const [localCompareSelected, setLocalCompareSelected] = useState([]);
  const [diagramsAvailable, setDiagramsAvailable] = useState(false);
  const [hasDiagrams, setHasDiagrams] = useState(false);
  const [unitSystemOptions, setUnitSystemOptions] = useState([]);
  const [selectedUnitSystem, setSelectedUnitSystem] = useState();
  const [isWaffleMenuOpen, setWaffleMenuOpen] = useState(false);
  const [isAccountPanelOpen, setAccountPanelOpen] = useState(false);

  const [dataLength, setDataLength] = useState(0);
  const [previousFavoriteList, setPreviousFavoriteList] = useState([]);
  const [mbaseLinkWidth, setMbaseLinkWidth] = useState(0);

  const selectedEntitiesRef = useRef(selectedEntities);

  const unitSystemDropdown = React.createRef();
  const mbaseLinkRef = React.createRef();

  const gridSize = 2;

  useEffect(() => {
    if ((currentTool && currentTool === 'lcaco2') || (qs.current.tool && qs.current.tool.toLowerCase() === 'lca')) {
      closePanel();
      setCo2CalcVisible(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [qs.current, currentTool]);

  useEffect(() => {
    selectedEntitiesRef.current = selectedEntities;
  }, [selectedEntities, appContent]);

  useEffect(() => {
    if (mbaseLinkRef.current) {
      setMbaseLinkWidth(mbaseLinkRef.current.clientWidth);
    }
  }, [mbaseLinkRef]);

  useEffect(() => {
    for (let selectedEntity of localCompareSelected) {
      if (selectedEntity.dia && selectedEntity.dia.length > 0) {
        setHasDiagrams(true);
        return;
      }
    }

    setHasDiagrams(false);
  }, [localCompareSelected]);
  const handleSelectedEntitiesChange = useCallback(
    (newSelectedEntities) => {
      //setAdvancedSearchVisible(false);

      let showLimitDialog = false;

      /*       if (newSelectedEntities.length > selectionLimit) {
        showLimitDialog = true;
      } */

      //logic to persist grade selection
      let oldSelections = JSON.parse(localStorage.getItem(appContent.shortname + '-selectedEntities'));

      if (isEqual(oldSelections, newSelectedEntities) && !isEmpty(newSelectedEntities)) {
        // don't need to do anything
        return;
      }

      if (newSelectedEntities && newSelectedEntities.length === 0 && oldSelections && oldSelections.length > 1) {
        if (oldSelections.length) {
          newSelectedEntities = newSelectedEntities.concat(oldSelections);
          newSelectedEntities = [...new Map(newSelectedEntities.map((selection) => [selection['id'], selection])).values()];
        }
      }

      /* newSelectedEntities = newSelectedEntities.filter(entity => {
        if (newSelectedEntities.length > selectionLimit) {
          let found = false;
          for (let selectedEntity of selectedEntitiesRef.current) {
            if (entity && entity.id && entity.id === selectedEntity.id) {
              found = true;
              break;
            }
          }

          return found;
        } else {
          return entity && entity.id;
        }
      }); */

      setSelectedEntities(newSelectedEntities);
      localStorage.setItem(appContent.shortname + '-selectedEntities', JSON.stringify(newSelectedEntities));

      let materialIds = newSelectedEntities
        .map((entity) => {
          if (entity) {
            return entity.id;
          } else {
            return null;
          }
        })
        .filter((id) => {
          if (id) {
            return true;
          } else {
            return false;
          }
        });

      if (materialIds.length > 1) {
        setCompareIds(materialIds);
        getCompareDetails(materialIds, qs.current);
      } else {
        setCompareIds([]);
        getCompareDetails([], qs.current);
      }

      let regex = /datasheet\/([^/]+)/;
      let product = document.location.pathname.match(regex);

      if (materialIds.length === 0 && !product) {
        document.title = defaultTitle;

        setDetailsVisible(false);
      }

      if (materialIds.length > 0) {
        setCompareButtonDisabled(false);
      } else {
        setCompareButtonDisabled(true);
        setCompareVisible(false);
      }

      if (showLimitDialog) {
        showLimitSelectionDialog();
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [getCompareDetails, appContent, selectionLimit]
  );

  useEffect(() => {
    if (scrollablePane && scrollablePane.contentContainer) {
      scrollablePane.contentContainer.onscroll = () => {
        let button = document.getElementById('scroll-top-button');

        let minYPosition = config?.scrolltotop?.minYPosition ? config.scrolltotop.minYPosition : 100;
        if (scrollablePane.contentContainer.scrollTop > minYPosition) {
          if (button) {
            button.style.display = 'block';
          }
        } else {
          if (button) {
            button.style.display = 'none';
          }
        }
      };
    }
  }, [scrollablePane, config]);

  useEffect(() => {
    if (roots.status) {
      getMaterials(qs.current, sortColumn, sortDirection, entityView);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [entityView]);

  useEffect(() => {
    if (preferences && userUnitSystems) {
      let options = [];
      if (userUnitSystems && userUnitSystems.options) {
        for (let [key, value] of Object.entries(userUnitSystems.options)) {
          options.push({ key: key, text: value });
        }
      }

      setUnitSystemOptions(options);

      if (preferences.unitSystem) {
        setSelectedUnitSystem(preferences.unitSystem.toString());
      } else {
        setSelectedUnitSystem(userUnitSystems.default.toString());
      }
    }
  }, [userUnitSystems, preferences, setSelectedUnitSystem]);

  function handleUnitSystemDropdownChange(e, value) {
    setSelectedUnitSystem(value.key);
    onConfirmUserPreferencesDialog({ language: language, unitSystem: value.key, skipLanding: skipLanding });
  }

  const onViewClick = useCallback(() => {
    if (entityView === 'table') {
      setEntityView('tiles');
    } else {
      setEntityView('table');
    }
  }, [entityView, setEntityView]);

  const setTableView = useCallback(() => {
    setEntityView('table');
  }, [setEntityView]);

  const setTileView = useCallback(() => {
    setEntityView('tiles');
  }, [setEntityView]);

  const updateBreadcrumbs = useCallback((breadcrumbs) => {
    setSearchBreadcrumbs(breadcrumbs);
  }, []);

  useEffect(() => {
    let freeuser = appContent && appContent.user && appContent.user.email === 'amdc-free@altair.com' ? true : false;

    let defaultTheme = 'light';

    if (appContent && !localStorage.getItem('amdc-theme')) {
      if (window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches) {
        defaultTheme = 'dark';
      }

      if (appContent.config && appContent.config.semanticcolors && appContent.config.semanticcolors.default) {
        defaultTheme = appContent.config.semanticcolors.default;
      }

      if (!freeuser) {
        if (preferences && preferences.theme) {
          defaultTheme = preferences.theme;
        }
      }

      setTheme(defaultTheme, appContent);
    } else if (appContent && appContent.config && appContent.config.semanticcolors && localStorage.getItem('amdc-theme')) {
      setTheme(localStorage.getItem('amdc-theme'), appContent);
    }
  }, [appContent, setTheme, saveUserPreferences, preferences]);

  function search(value) {
    if ((!isEmpty(value?.trim()) && value?.trim() !== qs.current.pattern) || (qs.current.pattern && isEmpty(value?.trim()))) {
      qs.current.pattern = value?.trim();

      setQuickSearchValue('');

      if (!value) {
        delete qs.current.pattern;
      }
      updateLoadIndex(true);
      updateOffset();
      cleanEntityItems();
      let searchStr = convertObjecttoQs(qs.current);

      let pageRegex = /\/page\/[^/]+/;

      let newURL = history.location.pathname.replace(pageRegex, '/');

      let url = newURL + searchStr;

      if (matomo) {
        matomo.siteSearch({ keywords: value?.split(' ') });
      }

      history.push(url);
    }
  }

  function executeLandingSearch(value) {
    setCurrentPage('Main');
    search(value);

    getMaterials(qs.current, sortColumn, sortDirection, entityView);
  }

  function updateQuickSearch(e, value) {
    if (value) {
      setQuickSearchValue(value);
    } else {
      setQuickSearchValue('');
    }
  }

  const favoritedClick = useCallback(
    (e, value, newFavoriteList = null) => {
      let newFilterData = JSON.parse(JSON.stringify(roots.filterData)) || {};
      if (value) {
        newFilterData.idfilter = newFavoriteList ? newFavoriteList : favoriteList;
      } else {
        newFilterData.idfilter = [];
      }

      newFilterData.new = true;

      if (!newFilterData.featfilter) {
        newFilterData.featfilter = [];
      }
      replaceFilterData(newFilterData);
    },
    [replaceFilterData, favoriteList, roots.filterData]
  );

  const removeSimilarSearch = useCallback(() => {
    let newFilterData = JSON.parse(JSON.stringify(roots.filterData)) || {};
    newFilterData.similar = null;
    setSimilarId(null);
    setSimilarProfile(null);
    replaceFilterData(newFilterData);
  }, [setSimilarId, replaceFilterData, roots.filterData, setSimilarProfile]);

  const findSimilarMaterials = useCallback(
    (item, profile = null) => {
      let newFilterData = JSON.parse(JSON.stringify(roots.filterData)) || {};
      if (item && item.id) {
        newFilterData.similar = item.id;
        if (profile && profile.key !== 'default') {
          newFilterData.similar = item.id + '-' + profile.key;
          setSimilarProfile(profile);
        } else {
          setSimilarProfile(null);
        }
        newFilterData.new = true;

        if (!newFilterData.featfilter) {
          newFilterData.featfilter = [];
        }

        setSimilarId(item.id);
        replaceFilterData(newFilterData);
      }
    },
    [roots.filterData, replaceFilterData, setSimilarId, setSimilarProfile]
  );

  const recursiveFilterSearch = useCallback((value, children) => {
    children = children.filter((child) => {
      if (child.name.toLowerCase().includes(value.toLowerCase())) {
        return true;
      } else if (child.children) {
        child.children = recursiveFilterSearch(value, child.children);
        if (child.children.length > 0) {
          return true;
        }
      }
      return false;
    });

    return children;
  }, []);

  const searchFilters = useCallback(
    (value) => {
      let newTags = JSON.parse(JSON.stringify(tags));

      for (const tag of newTags) {
        tag.children = recursiveFilterSearch(value, tag.children);
      }

      setFilteredTags(newTags);
    },
    [recursiveFilterSearch, tags]
  );

  const handleContactUsClick = useCallback(() => {
    setIsContactUsDialogHidden(false);
  }, []);

  const handleNoDriveLicenseClick = useCallback(() => {
    setNoDriveLicenseDialogHidden(false);
  }, []);

  function handleUpgradeClick() {
    openBBPageModal('page=upgrade');
  }

  function onDismissLimitSelectionDialog() {
    setIsLimitSelectionDialogHidden(true);
  }

  function onDismissLimitFavoritesDialog() {
    setIsLimitFavoritesDialogHidden(true);
  }

  function onDismissBBPageDialog() {
    if (bbPage === 'page=upgrade') {
      document.cookie = 'welcomeDisplayed=true; expires=Fri, 31 Dec 9999 23:59:59 GMT';
    }
    setIsBBPageDialogHidden(true);
  }

  const showLimitSelectionDialog = useCallback(() => {
    setIsLimitSelectionDialogHidden(false);
  }, []);

  const showLimitFavoritesDialog = useCallback(() => {
    setIsLimitFavoritesDialogHidden(false);
  }, []);

  const onDismissUserPreferencesDialog = useCallback(() => {
    setUserPreferencesDialogHidden(true);
    document.title = defaultTitle;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [config]);

  const onConfirmUserPreferencesDialog = useCallback(
    (prefs) => {
      if (preferences && preferences.id) {
        prefs.id = preferences.id;
      }

      prefs.tableProps = preferences.tableProps;

      if (languageOverride && prefs.language !== languageOverride) {
        const urlParams = new URLSearchParams(history.location.search);

        let regex = /\/[a-z]{2}/;
        history.push({
          pathname: window.location.pathname.replace(regex, ''),
          search: urlParams.toString(),
        });
      }

      saveUserPreferences(prefs);
      onDismissUserPreferencesDialog();
      //closePanel();
    },
    [preferences, onDismissUserPreferencesDialog, saveUserPreferences, languageOverride, history]
  );

  const handleSaveFavorites = useCallback(async () => {
    let criterias = [{ type: 'idfilter', value: favoriteList }];

    const searchCriteriaInput = {
      id: currentFavoriteId ? currentFavoriteId : null,
      name: 'USER_FAVORITES',
      format: FAVORITE_FORMAT,
      criterias: criterias,
      qs: qs.current,
    };

    const { data } = await api.materials.saveSearchCriteria(searchCriteriaInput, appContent);
    if (data.criteria && data.criteria.id) {
      setCurrentFavoriteId(data.criteria.id);
    }

    //getSavedFavorites();
  }, [api.materials, qs, appContent, currentFavoriteId, favoriteList]);

  const updateFavoriteStatus = useCallback(
    (id) => {
      if (favoriteList.includes(id)) {
        setFavoriteList(favoriteList.filter((item) => item !== id));
      } else {
        if (favoriteList.length < favoriteLimit) {
          setFavoriteList([...favoriteList, id]);
        } else {
          showLimitFavoritesDialog();
        }
      }
    },
    [favoriteList, setFavoriteList, showLimitFavoritesDialog, favoriteLimit]
  );

  useEffect(() => {
    if (appContent) {
      if (favoriteList.length < previousFavoriteList.length && favoriteView) {
        favoritedClick(null, true);
      }

      if (favoriteList.length === 0) {
        setFavoriteView(false);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [favoriteList, appContent]);

  const onDismissCustomizeTablePanel = useCallback(() => {
    setCustomizePanelHidden(true);
  }, []);

  const onDismissWaffleMenu = useCallback(() => {
    setWaffleMenuOpen(false);
  }, []);

  const onDismissAccountPanel = useCallback(() => {
    setAccountPanelOpen(false);
  }, []);

  const handleWaffleMenuClick = useCallback(() => {
    setWaffleMenuOpen(!isWaffleMenuOpen);
  }, [isWaffleMenuOpen]);

  const handleNotificationBellClick = useCallback(() => {
    if (panelId !== 'NOTIFICATIONS') {
      replacePanel('NOTIFICATIONS');
    } else {
      replacePanel('');
    }
  }, [replacePanel, panelId]);

  const handlePanelDismiss = useCallback(() => {
    replacePanel('');
  }, [replacePanel]);

  const handleUserButtonClick = useCallback(() => {
    if (config && !config.accountpanel) {
      if (panelId !== 'ACCOUNT') {
        replacePanel('ACCOUNT');
      } else {
        replacePanel('');
      }
    } else {
      if (!isAccountPanelOpen) {
        setAccountPanelOpen(true);
      } else {
        setAccountPanelOpen(false);
      }
    }
  }, [replacePanel, panelId, config, isAccountPanelOpen]);

  const onConfirmCustomizeTablePanel = useCallback(
    (itemMap) => {
      let prefs = {};
      let tableProps = [];

      if (preferences && preferences.id) {
        prefs.id = preferences.id;
      }

      prefs.language = language;
      prefs.unitSystem = unitSystem;

      let itemArray = Array.from(itemMap.values());

      for (let item of itemArray) {
        if (item.checked) {
          tableProps.push(item);
        }
      }

      prefs.tableProps = tableProps;

      saveUserPreferences(prefs);
      onDismissCustomizeTablePanel();
      closePanel();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [preferences, language, unitSystem, onDismissCustomizeTablePanel, saveUserPreferences]
  );

  function notifyAdvancedSearchResizeStop() {
    setDetailsResize(false);
  }

  const fetchMoreData = useCallback(() => {
    if (roots.status && roots.status !== 'FETCHING') {
      updateLoadIndex(false);
      updateOffset(offset + pageSize);
      getMaterials(qs.current, sortColumn, sortDirection, entityView);
    }
  }, [roots.status, offset, pageSize, qs, sortColumn, sortDirection, entityView, getMaterials, updateOffset, updateLoadIndex]);

  function notifyAdvancedSearchResizeStart() {
    setDetailsResize(true);
  }

  function notifyDetailsResizeStop() {
    setDetailsResize(false);
  }

  function notifyDetailsResizeStart() {
    setDetailsResize(true);
  }

  function notifyCompareResizeStop() {
    setCompareResize(false);
  }

  function notifyCompareResizeStart() {
    setCompareResize(true);
  }

  function notifyDetailsFullScreen() {}

  function notifyAdvancedSearchFullScreen() {}

  function notifyCompareFullScreen(isFullScreen) {
    if (isFullScreen) {
      setCompareLayout('column');
      setTimeout(setCompareFullScreen, 500, true);
    } else {
      setCompareLayout('row');
      setTimeout(setCompareFullScreen, 500, false);
    }
  }

  window.bbPageModal = function bbPageModal(event, page) {
    if (event) {
      event.preventDefault();
    }
    openBBPageModal(page);
  };

  window.showDatasheet = function showDatasheet(event, id, appCatalogId = null) {
    if (event) {
      event.preventDefault();
    }

    let entity = { id: id };

    onEntityInvoke(entity, { push: true, internalLink: true, appCatalogId: appCatalogId });
  };

  function openBBPageModal(page) {
    setBBPage(page);
    setIsBBPageDialogHidden(false);
  }

  function onCompareTabClick(itemKey) {
    setActiveCompareTab(itemKey);
  }

  const closePanel = useCallback(
    (removeDatasheet = true) => {
      document.title = defaultTitle;
      const urlParams = new URLSearchParams(history.location.search);

      let newURL = window.location.pathname;

      if (removeDatasheet) {
        let regex = /datasheet\/[^/]+\/[^/]+\/[^/]+/;
        newURL = window.location.pathname.replace(regex, '');

        let toolRegex = /\/toolbox\/[^/]+/;

        newURL = newURL.replace(toolRegex, '');

        history.push({
          pathname: !isEmpty(newURL) ? newURL : '/',
          search: urlParams.toString(),
        });
      }

      setActiveEntity(null);
      setSearchVisible(false);
      setDetailsVisible(false);
      setCompareVisible(false);
      setAdvancedSearchVisible(false);
      setCustomizePanelHidden(true);
      setScatterPlotVisible(false);
      setCo2CalcVisible(false);
    },
    [defaultTitle, history]
  );

  function closeCo2Calc() {
    setCo2CalcVisible(false);
  }

  const onActiveEntityChanged = useCallback(
    (entity) => {
      setActiveEntity(entity);
      getMaterialDatasheet(entity.id, qs.current, appContent);
    },
    [getMaterialDatasheet, appContent, qs]
  );

  const onEntityInvoke = useCallback(
    (entity, options = { push: true }) => {
      if (options.internalLink && activeEntity) {
        setDatasheetHistory((datasheetHistory) => [...datasheetHistory, activeEntity.id]);
      } else if (!options.internalLink) {
        setDatasheetHistory([]);
      }

      closePanel(false);
      setDetailsVisible(true);
      setActiveEntity(entity);

      let tracking = track.get(entity.id);

      let id = entity.id;

      if (entity.name) {
        id = entity.name;
      }

      if (entity.materialname) {
        id = entity.materialname;
      }

      let hash = null;

      if (tracking) {
        hash = tracking.url && tracking.url.length > 3 ? tracking.url[3] : null;
        if (tracking.url && tracking.url.length > 1) {
          id = tracking.url[1];
        }
      } else {
        let regex = /datasheet\/[^/]+\/[^/]+\/([^/]+)/;

        let hashPart = history.location.pathname.match(regex);

        if (hashPart && hashPart.length > 1) {
          hash = hashPart[1];
        }
      }

      getMaterialDatasheet(encodeURIComponent(id), qs.current, appContent, hash, options.appCatalogId);
    },
    [appContent, getMaterialDatasheet, qs, track, closePanel, history.location.pathname, activeEntity]
  );

  const navigateBack = useCallback(() => {
    let newStack = [...datasheetHistory];
    let previousId = newStack.pop();

    setDatasheetHistory(newStack);
    onEntityInvoke({ id: previousId });
  }, [datasheetHistory, setDatasheetHistory, onEntityInvoke]);

  const onDownloadClick = useCallback(
    (
      model,
      solver,
      unit,
      startNumber,
      moistureState,
      reductionFactor,
      temperature,
      poissonsRatio,
      engTrueStressStrain,
      stressLimit,
      includeGeneric,
      encrypted,
      diagram,
      dest = 'local'
    ) => {
      if (roots.perms.includes('ui.caecards')) {
        if (
          solver.category === 'Solver' &&
          materialDatasheet &&
          materialDatasheet &&
          materialDatasheet.data &&
          materialDatasheet.data.length > 0
        ) {
          let materialId = null;

          for (let buildingBlock of materialDatasheet.data) {
            if (buildingBlock.type === 'title') {
              materialId = buildingBlock.data.materialId;
            }
          }

          let unitSystemShortName = appContent?.unitsystems?.shortnames[unitSystem];

          let tracking = track.get(materialId);

          if (tracking && matomo) {
            let payload = {
              list: solver.key + '~' + model.text,
              name: tracking.name,
              producer: tracking.producer && tracking.producer.length > 0 ? tracking.producer.join('~') : '',
              language: language,
              unitsystem: unitSystemShortName,
              application: appContent.shortname,
              provider: tracking.provider && tracking.provider.length > 0 ? tracking.provider.join('~') : '',
              materialtype: tracking.materialtype && tracking.materialtype.length > 0 ? tracking.materialtype.join('~') : '',
              dest: dest,
            };

            if (dest !== 'preview') {
              matomo.caedownload(payload);
            }
          }

          downloadMaterialCard(
            materialId,
            model,
            solver,
            unit,
            startNumber,
            moistureState,
            qs.current,
            reductionFactor,
            temperature,
            unit.key,
            poissonsRatio,
            engTrueStressStrain,
            stressLimit,
            includeGeneric,
            encrypted,
            appContent,
            webSocket,
            diagram,
            dest
          );
        } else {
          let selectedMaterialIds = [];
          for (const entity of selectedEntities) {
            selectedMaterialIds.push(entity.id);
          }
          exportMaterials(selectedMaterialIds, solver.text, solver.key);
        }
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [appContent, roots.perms, downloadMaterialCard, exportMaterials, selectedEntities, webSocket, materialDatasheet, matomo, track]
  );

  function closeWelcomePanel() {
    setIsUpgradeDialogHidden(true);
    document.cookie = 'welcomeDisplayed=true; expires=Fri, 31 Dec 9999 23:59:59 GMT';
  }

  function onFileChange(e) {
    setFile(e.target.files[0]);
  }

  function onSourceTemplateChange(e, value) {
    setImportSourceTemplate(value);
  }

  const shareFavoriteList = useCallback(async () => {
    let criterias = [{ type: 'idfilter', value: favoriteList }];

    let name = crypto.randomUUID();
    const searchCriteriaInput = {
      id: null,
      name: name,
      format: FORMAT,
      criterias: criterias,
      qs: qs.current,
      global: true,
      timestamp: Date.now(),
    };

    const { data } = await api.materials.saveSearchCriteria(searchCriteriaInput, appContent);
    if (data.criteria && data.criteria.id) {
      //setCurrentId(data.criteria.id);
    }

    let shareURL = window.location.protocol + '//' + window.location.host + window.location.pathname + '?favorites=' + name;

    return shareURL;
  }, [api.materials, appContent, favoriteList]);

  const copyFavoritesToClipboard = useCallback(async () => {
    let shareURL = await shareFavoriteList();
    navigator.clipboard.writeText(shareURL);
    notifyShared();
  }, [shareFavoriteList, notifyShared]);

  const sendFavoritesViaEmail = useCallback(async () => {
    let shareURL = await shareFavoriteList();
    let mailText = TextMapping.getUIText(TextMapping.UI_TEXT_SHARE_FAVORITES_TEXT, texts, {
      shareURL: shareURL,
    });
    window.location.href = `mailto:?${mailText}`;
  }, [shareFavoriteList, texts]);

  function onProviderChange(e, value) {
    setImportProvider(value);

    let cdefs = [];
    for (const item of derivedContentDefinitions) {
      if (item.cdef.definition.name === 'CommonSourceDirectory') {
        cdefs.push(...item.derivedCdefs);
      } else {
        if (item.cdef.definition.displayName.includes(value.text)) {
          cdefs.push(item);
        }
      }
    }

    setProviderCdefs(cdefs);
  }

  function onUnitSystemChange(e, value) {
    setExportUnitSystem(value);
  }

  function onFlagChange(e, value) {
    setExportFlag(value);
  }

  const onDownloadPDF = useCallback(
    (item, name) => {
      let tracking = track.get(item.id);

      let unitSystemShortName = appContent?.unitsystems?.shortnames[unitSystem];

      if (tracking && matomo) {
        let payload = {
          name: tracking.name,
          producer: tracking.producer && tracking.producer.length > 0 ? tracking.producer.join('~') : '',
          language: language,
          unitsystem: unitSystemShortName,
          application: appContent.shortname,
          provider: tracking.provider && tracking.provider.length > 0 ? tracking.provider.join('~') : '',
          materialtype: tracking.materialtype && tracking.materialtype.length > 0 ? tracking.materialtype.join('~') : '',
        };

        matomo.datasheetPDF(payload);
      }

      downloadPDF(item.id, name, appContent);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [appContent, downloadPDF, matomo, track]
  );

  const onCommandBarClick = useCallback(
    (commandKey, commandValue = undefined) => {
      let selectedMaterialIds = [];
      for (const entity of selectedEntities) {
        selectedMaterialIds.push(entity.id);
      }
      if (commandKey === 'import') {
        toggleImportModal(true);
      } else if (commandKey === 'search') {
        if (searchVisible) {
          setSearchVisible(false);
        } else {
          if (scrollableContentRef) {
            scrollableContentRef.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
          }
          setDetailsVisible(false);
          setSearchVisible(true);
        }
      } else if (commandKey === 'favorites') {
        if (!isEmpty(favoriteList)) {
          if (favoriteView) {
            favoritedClick(null, false);
            setFavoriteView(false);
          } else {
            favoritedClick(null, true);
            setFavoriteView(true);
          }
        }
      } else if (commandKey === 'scatterplot') {
        if (scatterPlotVisible) {
          setScatterPlotVisible(false);
        } else {
          closePanel();
          setScatterPlotVisible(true);

          document.title = 'Scatter Plot - ' + defaultTitle;
        }
      } else if (commandKey === 'co2calc') {
        if (co2CalcVisible) {
          setCo2CalcVisible(false);
        } else {
          closePanel();
          setCo2CalcVisible(true);
        }
      } else if (commandKey === 'newsearch') {
        if (advancedSearchVisible) {
          setAdvancedSearchVisible(false);
        } else {
          closePanel();
          setAdvancedSearchVisible(true);
          document.title = 'Advanced Search  - ' + defaultTitle;
        }
      } else if (commandKey === 'compare') {
        closePanel();
        setCompareVisible(true);
        document.title = 'Compare Materials - ' + defaultTitle;
      } else if (commandKey === 'qtexport') {
        qtExportMaterials(selectedMaterialIds, 'Inspire', 'Inspire', qtHandler);
      } else if (commandKey === 'customize') {
        closePanel();
        setCustomizePanelHidden(false);
      } else if (commandKey === 'preferences') {
        setUserPreferencesDialogHidden(false);
        document.title = 'User Preferences - ' + defaultTitle;
      } else if (commandKey === 'clearfavorites') {
        setFavoriteList([]);
        favoritedClick(null, false);
        setFavoriteView(false);
      } else if (commandKey === 'addtocompare') {
        let newSelectedEntities = [...selectedEntities];
        let existingIds = [];
        for (let entity of newSelectedEntities) {
          existingIds.push(entity.id);
        }

        for (let favorite of favoriteList) {
          if (!existingIds.includes(favorite)) {
            newSelectedEntities.push({ id: favorite });
          }
        }

        handleSelectedEntitiesChange(newSelectedEntities);
      } else if (commandKey === 'savefavorites') {
        setIsSaveFavoritesAsDialogHidden(false);
      } else if (commandKey === 'loadfavorites') {
        setIsLoadFavoritesDialogHidden(false);
      } else if (commandKey === 'copytoclipboard') {
        copyFavoritesToClipboard();
      } else if (commandKey === 'sendviaemail') {
        sendFavoritesViaEmail();
      } else if (commandKey === 'addtofavorites') {
        let idsToAdd = [];
        for (let entity of selectedEntities) {
          if (!favoriteList.includes(entity.id)) {
            idsToAdd.push(entity.id);
          }
        }

        let newList = [...favoriteList, ...idsToAdd];
        if (newList.length < favoriteLimit) {
          setFavoriteList(newList);
        } else {
          showLimitFavoritesDialog();
        }
      } else {
        exportMaterials(selectedMaterialIds, commandValue, commandKey);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      advancedSearchVisible,
      exportMaterials,
      qtExportMaterials,
      qtHandler,
      scrollableContentRef,
      searchVisible,
      selectedEntities,
      toggleImportModal,
      co2CalcVisible,
      scatterPlotVisible,
      favoriteView,
      favoritedClick,
      setFavoriteList,
      handleSelectedEntitiesChange,
      setIsSaveFavoritesAsDialogHidden,
      favoriteList,
      favoriteLimit,
    ]
  );

  function addAction(key, item, defaultIcon, onClick, newActions) {
    if (!item.permission || (item.permission && roots.perms.includes(item.permission))) {
      newActions.push({
        key: key,
        iconProps: {
          iconName: item.icon ? item.icon : defaultIcon,
          styles: {
            root: { color: theme === 'light' ? '#000000' : '#FFFFFF' },
          },
        },
        buttonStyles: {
          root: { background: 'none' },
          rootHovered: { background: '#ffffff', opacity: '0.8' },
        },
        name: item.uiname ? TextMapping.getUIText(item.uiname, texts) : item.name,
        onClick: onClick,
      });
    }
  }

  let actions = useMemo(() => {
    let newActions = [];

    if (config.navbar) {
      if (config.navbar.content) {
        for (let item of config.navbar.content) {
          if (item.action === 'gwpcalc') {
            addAction('CO2_CALC', item, 'calculator', () => onCommandBarClick('co2calc'), newActions);
          } else if (item.action === 'advancedsearch') {
            addAction('NEW_SEARCH', item, 'Search', () => onCommandBarClick('newsearch'), newActions);
          } else if (item.action === 'favorites' && (!item.permission || (item.permission && roots.perms.includes(item.permission)))) {
            let subMenuItems = [];

            if (item.content && item.content.length) {
              for (let subitem of item.content) {
                let newItem = {
                  key: subitem.uiname,
                  text: TextMapping.getUIText(subitem.uiname, texts),
                  iconProps: {
                    iconName: subitem.icon,
                    styles: subitem.styles,
                  },
                  onClick: () => onCommandBarClick(subitem.action),
                  disabled: !(typeof subitem.mincount === 'undefined') && favoriteList.length < subitem.mincount,
                };

                if (subitem.action === 'favoritesaction') {
                  newItem.onClick = () => favoriteAction(subitem.url, subitem.payload, favoriteList);
                }

                if (subitem.content && subitem.content.length) {
                  let grandchildren = [];
                  for (let grandchild of subitem.content) {
                    let newItem = {
                      key: grandchild.uiname,
                      text: TextMapping.getUIText(grandchild.uiname, texts),
                      iconProps: { iconName: grandchild.icon },
                      onClick: () => onCommandBarClick(grandchild.action),
                      disabled: !(typeof grandchild.mincount === 'undefined') && favoriteList.length < grandchild.mincount,
                    };

                    if (grandchild.action === 'favoritesaction') {
                      newItem.onClick = () => favoriteAction(grandchild.url, grandchild.payload, favoriteList);
                    }

                    if (
                      (grandchild.action !== 'savefavorites' && grandchild.action !== 'loadfavorites') ||
                      (loggedInUserEntity && loggedInUserEntity.id)
                    ) {
                      grandchildren.push(newItem);
                    }
                  }

                  newItem.subMenuProps = { items: grandchildren };
                }

                if (!subitem.permission || (subitem.permission && roots.perms.includes(subitem.permission))) {
                  subMenuItems.push(newItem);
                }
              }
            }
            let subMenuProps = { items: subMenuItems };
            newActions.push({
              key: 'FAVORITES',
              split: true,
              iconProps: {
                iconName: favoriteView ? 'FavoriteStarFill' : 'FavoriteStar',
                styles: item.styles,
              },
              buttonStyles: {
                root: { background: 'none' },
                rootHovered: { background: '#ffffff', opacity: '0.8' },
                ':hover': {
                  color: '#FFFFFF',
                },
              },
              name: item.uiname ? TextMapping.getUIText(item.uiname, texts) : item.name,
              subMenuProps: subMenuProps,
              onClick: () => onCommandBarClick('favorites'),
            });

            if (newActions.length && newActions[newActions.length - 1].key === 'FAVORITES')
              newActions[newActions.length - 1].name += ` (${favoriteList.length})`;
          } else if (item.action === 'scatterplot') {
            addAction('SCATTER_PLOT', item, 'ScatterChart', () => onCommandBarClick('scatterplot'), newActions);
          } else if (item.action === 'customizetable') {
            if (entityView === 'table') {
              addAction('CUSTOMIZE_TABLE', item, 'ColumnOptions', () => onCommandBarClick('customize'), newActions);
            }
          } else if (item.action === 'preferences') {
            addAction('USER_PREFERENCES', item, 'PlayerSettings', () => onCommandBarClick('preferences'), newActions);
          } else if (item.action === 'view') {
            item.name =
              entityView === 'table'
                ? item.uitilesname
                  ? TextMapping.getUIText(item.uitilesname, texts)
                  : item.tilesname
                : item.uitablename
                ? TextMapping.getUIText(item.uitablename, texts)
                : item.tablename;

            addAction('View', item, 'View', () => onViewClick(), newActions);
          } else if (item.action === 'mainmenu') {
            const onClick = () => {
              window.location.href = config.defaultview.url;
            };
            if (config.defaultview && config.defaultview.mainmenu) {
              addAction('MAINMENU', item, config.defaultview.mainmenu.icon, onClick, newActions);
            }
          } else if (item.action === 'compare') {
            let subMenuItems = [];

            if (item.content && item.content.length) {
              for (let subitem of item.content) {
                let newItem = {
                  key: subitem.uiname,
                  text: TextMapping.getUIText(subitem.uiname, texts),
                  iconProps: {
                    iconName: subitem.icon,
                    styles: subitem.styles,
                  },
                  onClick: () => onCommandBarClick(subitem.action),
                };

                if (subitem.action === 'clearselections') {
                  newItem.onClick = () => {
                    localStorage.setItem(appContent.shortname + '-selectedEntities', JSON.stringify([]));
                    setCompareIds([]);
                    getCompareDetails([], qs.current);
                    return setSelectedEntities([]);
                  };
                }

                if (!subitem.permission || (subitem.permission && roots.perms.includes(subitem.permission))) {
                  subMenuItems.push(newItem);
                }
              }
            }

            let iconColor = '#333333';
            if (
              appContent?.config?.semanticcolors &&
              appContent?.config?.semanticcolors[theme] &&
              appContent?.config?.semanticcolors[theme].iconColor
            ) {
              iconColor = appContent?.config?.semanticcolors[theme].iconColor;
            }
            newActions.push({
              key: 'COMPARE',
              split: true,
              iconProps: {
                iconName: item.icon ? item.icon : 'Compare',
                styles: {
                  root: { color: iconColor },
                },
              },
              buttonStyles: {
                root: { background: 'none' },
                rootHovered: { background: '#ffffff', opacity: '0.8' },
              },
              disabled: compareButtonDisabled,
              restrictions: {
                allowedSelectionCounts: ['MANY'],
              },
              subMenuProps: {
                items: subMenuItems,
              },
              name: (item.uiname ? TextMapping.getUIText(item.uiname, texts) : item.name) + ` (${selectedEntities.length})`,
              onClick: () => onCommandBarClick('compare'),
            });
          }
        }
      }
    }

    return newActions;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    compareButtonDisabled,
    config.advsearchdd,
    config.custtable,
    entityView,
    config.advsearchdialog,
    handleSelectedEntitiesChange,
    onCommandBarClick,
    onViewClick,
    selectedEntities.length,
    texts,
    appContent,
    favoriteList,
    theme,
  ]);

  const commandBarProps = { items: actions };
  const onColumnHeaderClick = (event, column) => {
    if (roots.status !== 'FETCHING' && !column.nosort) {
      let newSortColumn = column.key;
      let newSortDirection = 'ASC';

      if (sortColumn === column.key) {
        switch (sortDirection) {
          case 'ASC':
            newSortDirection = 'DESC';
            break;
          case 'DESC':
            newSortDirection = 'ASC';
            break;
          default:
            newSortDirection = 'ASC';
            break;
        }
      } else {
        // sorting by a different column
        if (column.type === 'exponent') {
          newSortDirection = 'DESC';
        }
      }

      setSortDirection(newSortDirection);
      setSortColumn(column.key);

      updateLoadIndex(true);
      updateOffset();
      cleanEntityItems();

      getMaterials(qs.current, newSortColumn, newSortDirection, entityView);
    }
  };

  const onModalDismiss = () => {
    toggleImportModal(false);
  };

  const onExportModalDismiss = () => {
    toggleExportModal(false);
  };

  const onLeftPanelControlClick = () => {
    setIsLeftPanelOpen((oldValue) => !oldValue);
  };

  const getSavedFavorites = useCallback(async () => {
    if (roots.appContent) {
      const { data } = await api.materials.getSearchCriteria(roots.appContent, FAVORITE_FORMAT);
      let criteriasData = get(data, 'criterias');

      let newSavedFavorites = [];

      for (let entry of criteriasData) {
        if (entry.name && entry.name === 'USER_FAVORITES') {
          setCurrentFavoriteId(entry.id);
          if (isEmpty(favoriteList)) {
            for (let criteria of entry.criterias) {
              if (criteria.type === 'idfilter') {
                setFavoriteList(criteria.value);
                setPreviousFavoriteList(criteria.value);
              }
            }
          }
        } else if (entry.id && entry.id.startsWith('SearchCriteria')) {
          for (let criteria of entry.criterias) {
            if (criteria.type === 'idfilter') {
              let favoriteListItem = { name: entry.name, favoriteList: criteria.value, id: entry.id };
              newSavedFavorites.push(favoriteListItem);
            }
          }
        }
      }

      let names = [];
      for (let savedFavorite of newSavedFavorites) {
        names.push(savedFavorite.name);
      }

      if (!isEmpty(names)) {
        setSavedFavoriteNames(names);
      }
      if (!isEmpty(newSavedFavorites)) {
        setSavedFavorites(newSavedFavorites);
      }

      setFetchedFavorites(true);
    }
  }, [roots.appContent, api.materials, favoriteList, setFavoriteList]);

  const handleSaveFavoritesAs = useCallback(
    async (name) => {
      let criterias = [{ type: 'idfilter', value: favoriteList }];

      const searchCriteriaInput = {
        name: name,
        format: FAVORITE_FORMAT,
        criterias: criterias,
        qs: qs.current,
      };

      await api.materials.saveSearchCriteria(searchCriteriaInput, appContent);

      getSavedFavorites();

      setIsSaveFavoritesAsDialogHidden(true);
    },
    [api.materials, qs, appContent, favoriteList, getSavedFavorites]
  );

  useEffect(() => {
    if (appContent && !fetchedFavorites) {
      getSavedFavorites();
    }
  }, [appContent, getSavedFavorites, fetchedFavorites]);

  useDebounce(
    () => {
      if (fetchedFavorites && !isEqual(favoriteList, previousFavoriteList)) {
        handleSaveFavorites();
        setPreviousFavoriteList(favoriteList);
      }
    },
    1000,
    [handleSaveFavorites, favoriteList, previousFavoriteList, fetchedFavorites]
  );

  const addNavigation = useCallback(() => {
    let backButton = '';
    if (datasheetHistory.length > 0) {
      backButton = (
        <IconButton
          data-testid="datasheet-back-buttoon"
          iconProps={{ iconName: 'Back' }}
          onClick={navigateBack}
          text={TextMapping.getUIText(TextMapping.UI_TEXT_BACK, texts)}
        />
      );
    }

    return backButton;
  }, [datasheetHistory, navigateBack, texts]);

  const handleLoadFavorites = (savedFavorite) => {
    setFavoriteList(savedFavorite.favoriteList);
    setIsLoadFavoritesDialogHidden(true);
    favoritedClick(null, true, savedFavorite.favoriteList);
    setFavoriteView(true);
  };

  const handleDeleteFavorites = useCallback(
    async (_criterias) => {
      const ids = _criterias.map((c) => c.id);
      await api.materials.removeSearchCriteria(ids);

      let newSavedFavorites = savedFavorites.filter((favorite) => {
        return !ids.includes(favorite.id);
      });

      setSavedFavorites(newSavedFavorites);
      setIsLoadFavoritesDialogHidden(true);
    },
    [api.materials, savedFavorites]
  );

  const onImportSave = () => {
    const getSupportedExtensions = (sourceTemplate) => {
      for (const item of derivedContentDefinitions) {
        if (item.cdef.definition.name === 'CommonSourceDirectory') {
          const commonDerivedCdefs = item.derivedCdefs;
          for (const commonItem of commonDerivedCdefs) {
            const detailedCommonCdefs = commonItem.derivedCdefs;
            for (const detailedItem of detailedCommonCdefs) {
              if (detailedItem.cdef.definition.name === sourceTemplate) {
                return detailedItem.cdef.definition.extensions;
              }
            }
          }
        } else {
          const providerDerivedCdefs = item.derivedCdefs;
          for (const providerItem of providerDerivedCdefs) {
            if (providerItem.cdef.definition.name === sourceTemplate) {
              return providerItem.cdef.definition.extensions;
            }
          }
        }
      }
      return [];
    };

    if (!importProvider) {
      alert('Please select a provider ');
      return;
    }

    if (!importSourceTemplate) {
      alert('Please select a source template ');
      return;
    }

    if (!file) {
      alert('Please select a source file ');
      return;
    }

    const supportedExtensions = getSupportedExtensions(importSourceTemplate.key);
    const sourceFileExtension = Path.extname(file.name);

    if (!supportedExtensions.includes(sourceFileExtension)) {
      alert('Please select source file with correct extension, only allow ' + supportedExtensions);
      return;
    }
    toggleImportModal(false);
    importMaterials(file, importProvider, importSourceTemplate);
  };

  const onExportClick = () => {
    toggleExportModal(false);
    exportMaterials(selectedEntities, 'hyperlife', exportUnitSystem, exportFlag);
  };

  const toggleTheme = () => {
    let newTheme = 'light';

    if (theme === 'light') {
      newTheme = 'dark';
    }

    let prefs = JSON.parse(JSON.stringify(preferences));
    prefs.theme = newTheme;

    let freeuser = appContent && appContent.user && appContent.user.email === 'amdc-free@altair.com' ? true : false;

    if (!freeuser) {
      saveUserPreferences(prefs, false);
    }
    setTheme(newTheme, appContent);
  };

  const showProviderHierarchy = useCallback((providerTagName, tags) => {
    let providerHierarchy = [];
    function copy(object) {
      return Object.assign({}, object);
    }

    function collectHierarchyNames(hierarchy, tag) {
      hierarchy.push(tag.name);

      if (tag.children) {
        tag.children.forEach((child) => {
          collectHierarchyNames(hierarchy, child);
        });
      }
    }

    function filterProviderTags(child) {
      if (providerTagName && child.name === providerTagName) {
        return true;
      }

      if (child.children) {
        return (child.children = child.children.map(copy).filter(filterProviderTags)).length > 0;
      }
    }

    tags.forEach((tag) => {
      if (tag.name === 'MaterialProvider') {
        let filteredTags = tag.children.map(copy).filter(filterProviderTags);
        if (filteredTags.length > 0) {
          collectHierarchyNames(providerHierarchy, filteredTags[0]);
        }
      }
    });

    return providerHierarchy;
  }, []);

  const handleContextMenu = useCallback(
    (item, event) => {
      if (!actions) {
        return;
      }

      const newContextMenuProps = createContextMenuProps({
        actions,
        item,
        event,
        onDismiss: () => setContextMenuProps(null),
        selectedEntities,
      });

      newContextMenuProps.target = { x: event.clientX, y: event.clientY };

      setContextMenuProps(newContextMenuProps);
    },
    [actions, selectedEntities]
  );

  let panelTitle = 'Content Details';

  if (materialDatasheet && materialDatasheet.data) {
    for (let buildingBlock of materialDatasheet.data) {
      if (buildingBlock.type === 'title') {
        panelTitle = buildingBlock.data.content;
        if (detailsVisible) {
          document.title = panelTitle + ' - ' + defaultTitle;
        }
      }

      if (buildingBlock.type === 'meta') {
        if (buildingBlock.data && buildingBlock.data.meta && buildingBlock.data.meta.length) {
          for (let entry of buildingBlock.data.meta) {
            let meta = document.head.querySelector(`meta[name="${entry.name}"]`);
            if (meta) {
              let existingMeta = document.head.querySelector(`meta[name="${entry.name}"]`);
              existingMeta.content = entry.content;
            }

            if (!meta) {
              meta = document.createElement('meta');
              meta.name = entry.name;
              meta.content = entry.content;
              document.getElementsByTagName('head')[0].appendChild(meta);
            }
          }
        }
      }
    }
  }

  let materialPropertiesTable = !isEmpty(materialDatasheet) ? (
    <Datasheet
      datasheetStatus={datasheetStatus}
      details={materialDatasheet}
      downloadPDF={downloadPDF}
      downloadXLS={downloadXLS}
      canAccess={canAccess}
      onDownloadClick={onDownloadClick}
      handleContactUsClick={handleContactUsClick}
      handleNoDriveLicenseClick={handleNoDriveLicenseClick}
      webSocket={webSocket}
      showProviderHierarchy={showProviderHierarchy}
      bbCompareDetails={bbCompareDetails}
      getBuildingBlockCompareDetails={getBuildingBlockCompareDetails}
      tags={tags}
      texts={texts}
      compareCatalog={compareCatalog}
      appContent={appContent}
      bbPageForComponent={bbPageForComponent}
      getBBPageForComponent={getBBPageForComponent}
      language={language}
      datasheetHistory={datasheetHistory}
      navigateBack={navigateBack}
      matomo={matomo}
      unitSystem={unitSystem}
      history={history}
    />
  ) : (
    ''
  );

  let filterButtons = useMemo(() => {
    const urlParams = new URLSearchParams(history.location.search);

    let filters = createFilterTags({ tags, urlParams });

    let reducedFilters = filters.reduce((accumulator, filter) => {
      if (!Array.isArray(accumulator[filter.categoryVkey])) {
        accumulator[filter.categoryVkey] = [filter];
      } else {
        accumulator[filter.categoryVkey].push(filter);
      }
      return accumulator;
    }, {});

    function recursiveTagFilter(filter, children) {
      if (children) {
        for (let child of children) {
          let splitFilter = filter.split('-')[1];

          if (splitFilter === child.vkey.toString()) {
            return false;
          } else if (child.children) {
            if (recursiveTagFilter(filter, child.children) === false) {
              return false;
            }
          }
        }
      }

      return true;
    }

    function handleTagRemove(key) {
      const urlParamTags = urlParams.get('tags').split(',');

      let selectedTag;

      for (let tag of tags) {
        if (tag.useMask.join('') === key) {
          selectedTag = tag;
        }
      }

      const newUrlParamTags = urlParamTags.filter((filter) => {
        return recursiveTagFilter(filter, selectedTag.children);
      });

      if (newUrlParamTags.length > 0) {
        urlParams.set('tags', newUrlParamTags);
      } else {
        urlParams.delete('tags');
      }

      updateLoadIndex(true);
      updateOffset();
      cleanEntityItems();

      history.push({
        pathname: window.location.pathname,
        search: urlParams.toString(),
      });
    }

    let showSpinner = firstLoad && roots.status !== 'SUCCESS';

    return roots.status === 'SUCCESS' || roots.totalCount > 0 ? (
      <div style={{ fontSize: '14px', padding: '10px', display: 'flex', alignItems: 'center', flexWrap: 'wrap' }}>
        <div style={{ whiteSpace: 'nowrap' }} className="bodyText">
          {showSpinner && <Spinner size={SpinnerSize.small} />}
          {!showSpinner && TextMapping.getUIText(TextMapping.UI_TEXT_SHOWING_RESULTS_FOR, texts, { total: roots.totalCount || '0' })}
        </div>
        {!isEmpty(qs.current.pattern) &&
          qs.current.pattern.split(',').map((key) => {
            if (!isEmpty(key)) {
              return (
                <FilterButton
                  key={key}
                  onClick={() => {
                    let newValue = qs.current.pattern
                      .replace(',' + key, '')
                      .replace(key + ',', '')
                      .replace(key, '')
                      .trim();
                    search(newValue);
                  }}
                  text={key}
                />
              );
            } else {
              return null;
            }
          })}
        {similarId && (
          <FilterButton
            key={similarId}
            onClick={() => removeSimilarSearch()}
            text={TextMapping.getUIText(TextMapping.UI_TEXT_MATERIALS_SIMILAR_TO, texts, {
              materialname: track.get(similarId).name,
              profile: similarProfile && similarProfile.name ? similarProfile.name : 'Default',
            })}
          />
        )}
        {favoriteView && (
          <FilterButton
            key={'favorites'}
            onClick={() => {
              favoritedClick(null, false);
              setFavoriteView(false);
            }}
            text={TextMapping.getUIText(TextMapping.UI_TEXT_FAVORITE_MATERIALS, texts)}
          />
        )}
        {Object.keys(reducedFilters).map((key) => {
          let filterText = reducedFilters[key][0].name;
          if (reducedFilters[key].length > 1) {
            filterText = TextMapping.getUIText(TextMapping.UI_TEXT_FILTER_NAME_AND_COUNT_MORE, texts, {
              name: reducedFilters[key][0].name,
              count: reducedFilters[key].length - 1,
            });
          }
          return <FilterButton key={key} onClick={() => handleTagRemove(key)} text={filterText} />;
        })}

        {searchBreadcrumbs}
      </div>
    ) : (
      ''
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    tags,
    history.location.search,
    cleanEntityItems,
    updateLoadIndex,
    updateOffset,
    searchBreadcrumbs,
    texts,
    similarId,
    favoriteView,
    roots.totalCount,
    roots.status,
    qs,
  ]);

  const scatterCBforRanges = function (rangesData) {
    setScatterUpdatedRanges(rangesData);
  };

  let body = <div></div>;

  useEffect(() => {
    window.addEventListener('UC_UI_CMP_EVENT', function (e) {
      setUcEvent(e);
    });
  }, []);

  useEffect(() => {
    let newDataLength = 0;

    if (entityView === 'tiles') {
      newDataLength = Math.trunc(items.length / pageSize) * pageSize;
    } else {
      for (let item of items) {
        if (item.data && item.data.content) {
          newDataLength += item.data.content.length;
        }
      }
    }

    setDataLength(newDataLength);
  }, [items, entityView, pageSize]);

  if (licenseState === LicenseState.CheckingOut) {
    return null;
  }

  if (
    isCacheInitialized &&
    appContent &&
    appContent.user &&
    appContent.user.licenseType === 'named' &&
    ![LicenseState.CheckoutSuccess, LicenseState.HeartbeatSuccess].includes(licenseState)
  ) {
    return <NoLicense licenseState={licenseState} />;
  }

  function exportExcel(mode) {
    exportCompareExcel(
      roots.appContent,
      mode,
      localCompareSelected.map((mat) => mat._key)
    );
  }

  let toolbar = [];

  if (config.toolbar) {
    if (config.toolbar.content) {
      for (let item of config.toolbar.content) {
        if (item.action === 'unitsystem') {
          toolbar.push(
            <Dropdown
              style={{ width: '150px' }}
              key="unit-system-dropdown"
              data-testid="userpref-unitsystem-dropdown"
              placeholder={item.name}
              id="unitSystemDropdown"
              ariaLabel={item.name}
              options={unitSystemOptions}
              onChange={handleUnitSystemDropdownChange}
              componentRef={unitSystemDropdown}
              defaultSelectedKey={selectedUnitSystem ? selectedUnitSystem : ''}
            />
          );
        } else if (item.action === 'tileview') {
          toolbar.push(
            <IconButton
              iconProps={{ iconName: item.icon ? item.icon : 'GridViewSmall' }}
              key="view-icon-tiles"
              disabled={entityView === 'tiles'}
              onClick={setTileView}
              title={item.name}
            />
          );
        } else if (item.action === 'tableview') {
          toolbar.push(
            <IconButton
              key="view-icon-table"
              iconProps={{ iconName: item.icon ? item.icon : 'List' }}
              disabled={entityView === 'table'}
              onClick={setTableView}
              title={item.name}
            />
          );
        } else if (item.action === 'theme') {
          toolbar.push(
            <IconButton
              key="view-icon-theme"
              iconProps={{ iconName: item.icon ? item.icon : 'Light' }}
              onClick={toggleTheme}
              title={item.name}
            />
          );
        }
      }
    }
  }

  let messageBar = shareMessage && (
    <div style={{ position: 'absolute', top: '0px', right: '20px', width: 'fit-content', zIndex: '1' }}>
      <MessageBar onDismiss={closeNotification} messageBarType={MessageBarType.info}>
        {shareMessage}
      </MessageBar>
    </div>
  );

  let entityViewComponent =
    entityView === 'tiles' ? (
      <TileView
        appContent={appContent}
        gridSize={gridSize}
        entities={items}
        catalogs={catalogs}
        pdfDownloadWaiting={pdfDownloadWaiting}
        downloadPDF={onDownloadPDF}
        onEntityInvoke={onEntityInvoke}
        onActiveEntityChanged={onActiveEntityChanged}
        handleSelectedEntitiesChange={handleSelectedEntitiesChange}
        showLimitSelectionDialog={showLimitSelectionDialog}
        selectedEntities={selectedEntities}
        history={history}
        texts={texts}
        track={track}
        updateFavoriteStatus={updateFavoriteStatus}
        favoriteList={favoriteList}
        notifyShared={notifyShared}
        messageBar={messageBar}
        activeId={activeId}
        similarId={similarId}
        findSimilarMaterials={findSimilarMaterials}
      />
    ) : (
      <BuildingBlockList
        appContent={appContent}
        data-testid="material-entity-list"
        buildingBlocksArray={items}
        enableShimmer={firstLoad && roots.status !== 'SUCCESS'}
        actions={actions}
        handleSelectedEntitiesChange={handleSelectedEntitiesChange}
        handleContextMenu={handleContextMenu}
        pdfDownloadWaiting={pdfDownloadWaiting}
        downloadPDF={onDownloadPDF}
        onEntityInvoke={onEntityInvoke}
        onActiveEntityChanged={onActiveEntityChanged}
        selectedEntities={selectedEntities}
        onColumnHeaderClick={onColumnHeaderClick}
        perms={perms}
        preferences={preferences}
        sortColumn={sortColumn}
        sortDirection={sortDirection}
        texts={texts}
        history={history}
        track={track}
        updateFavoriteStatus={updateFavoriteStatus}
        favoriteList={favoriteList}
        findSimilarMaterials={findSimilarMaterials}
        notifyShared={notifyShared}
        messageBar={messageBar}
        activeId={activeId}
        similarId={similarId}
      />
    );

  if (roots.status === 'SUCCESS' && roots.totalCount === 0) {
    entityViewComponent = (
      <NoResults
        qs={qs}
        history={history}
        preferences={preferences}
        texts={texts}
        canAccess={canAccess}
        onDownloadClick={onDownloadClick}
        handleContactUsClick={handleContactUsClick}
        webSocket={webSocket}
        executeSearch={executeLandingSearch}
        updateQuickSearch={updateQuickSearch}
        quickSearchValue={quickSearchValue}
        landingBB={landingBB}
        getLandingBuildingBlocks={getLandingBuildingBlocks}
        appContent={appContent}
        language={language}
        view={'noresults'}
      />
    );
  }

  let resultSummaries = [];
  if (config.resultsummary) {
    if (config.resultsummary.blocks && config.resultsummary.blocks.length > 0) {
      for (let block of config.resultsummary.blocks) {
        resultSummaries.push(
          <Margin5>
            <ResultSummary
              name={block.name}
              appContent={appContent}
              config={config}
              texts={texts}
              canAccess={canAccess}
              onDownloadClick={onDownloadClick}
              handleContactUsClick={handleContactUsClick}
              webSocket={webSocket}
              resultSummary={resultSummary}
              getResultSummary={getResultSummary}
              language={language}
              ranges={ranges}
            />
          </Margin5>
        );
      }
    }
  }
  if (isCacheInitialized) {
    if (
      (currentPage === 'Main' || (!currentPage && (!qs.current || !qs.current.view)) || (qs.current && qs.current.view === 'Main')) &&
      (!appContent || appContent.success !== false)
    ) {
      body = (
        <>
          <Helmet>
            {config?.enableUsercentrics && (
              <script>
                {`window.UC_UI_DOMAINS = {
                              crossDomainConsentSharingIFrame: '${window.location.origin}/cross-domain-bridge.html',
                            }
                            `}
              </script>
            )}

            {config?.enableUsercentrics && (
              <script
                id="usercentrics-cmp"
                src="https://app.usercentrics.eu/browser-ui/latest/loader.js"
                data-settings-id={config.enableUsercentrics.id}
                async
              ></script>
            )}

            {/* Google Tag Manager  */}
            {config?.enableGoogleAnalytics && (
              <script>
                {`
                          (function(w, d, s, l, i) {
                              w[l] = w[l] || [];
                              w[l].push({ 'gtm.start': new Date().getTime(), event: 'gtm.js' });
                              var f = d.getElementsByTagName(s)[0],
                                j = d.createElement(s),
                                dl = l != 'dataLayer' ? '&l=' + l : '';
                              j.async = true;
                              j.src = 'https://www.googletagmanager.com/gtm.js?id=' + i + dl;
                              f.parentNode.insertBefore(j, f);
                            })(window, document, 'script', 'dataLayer', '${config.enableGoogleAnalytics.token}');
                          `}
              </script>
            )}
            {/* End Google Tag Manager */}
            {/* Start of HubSpot Embed Code */}
            {config?.enableHubSpot && (
              <script type="text/javascript" id="hs-script-loader" async defer src="//js.hs-scripts.com/47251.js"></script>
            )}
            {/* End of HubSpot Embed Code */}
          </Helmet>
          <TopbarContainer
            onWaffleButtonClick={handleWaffleMenuClick}
            onNotificationBellClick={handleNotificationBellClick}
            onUserButtonClick={handleUserButtonClick}
            texts={texts}
          />
          <WaffleMenuContainer isOpen={isWaffleMenuOpen} dismissPanel={onDismissWaffleMenu} />
          <AccountPanelContainer isOpen={isAccountPanelOpen} dismissPanel={onDismissAccountPanel} />
          <Wrap>
            <Content>
              <IconButton
                style={{ display: isMobile ? 'block' : 'none' }}
                iconProps={{ iconName: 'Hamburger' }}
                onClick={onLeftPanelControlClick}
              />

              <LeftSidePanel
                history={history}
                updateQuickSearch={updateQuickSearch}
                quickSearchValue={quickSearchValue}
                search={search}
                qs={qs}
                resultSummaries={resultSummaries}
                filteredTags={filteredTags}
                sortColumn={sortColumn}
                sortDirection={sortDirection}
                searchFilters={searchFilters}
                isPanelOpen={isLeftPanelOpen}
                onPanelDismiss={() => setIsLeftPanelOpen(false)}
                scrollableTarget={scrollableContentRef}
                entityView={entityView}
                favoritedClick={favoritedClick}
                themeName={theme}
              />

              <Main>
                <ContactUsDialog
                  api={api}
                  appName="Material Data Center"
                  hidden={isContactUsDialogHidden}
                  onDismiss={() => setIsContactUsDialogHidden(true)}
                  title={
                    <>
                      Thank you for your interest in
                      <div style={{ textAlign: 'center' }}>Material Data Center</div>
                    </>
                  }
                />
                <NoDriveLicenseDialog
                  api={api}
                  appName="Material Data Center"
                  hidden={isNoDriveLicenseDialogHidden}
                  onDismiss={() => setNoDriveLicenseDialogHidden(true)}
                  texts={texts}
                />
                <UpgradeDialog api={api} hidden={isUpgradeDialogHidden} onDismiss={closeWelcomePanel} texts={texts} />
                <LimitSelectionDialog
                  hidden={isLimitSelectionDialogHidden}
                  onDismiss={onDismissLimitSelectionDialog}
                  texts={texts}
                  max={selectionLimit}
                />
                <LimitFavoritesDialog
                  hidden={isLimitFavoritesDialogHidden}
                  onDismiss={onDismissLimitFavoritesDialog}
                  texts={texts}
                  max={favoriteLimit}
                />
                <BBPageDialog
                  appContent={appContent}
                  api={api}
                  hidden={isBBPageDialogHidden}
                  bbPageForDialog={bbPageForDialog}
                  clearBBPageForDialog={clearBBPageForDialog}
                  getBBPageForDialog={getBBPageForDialog}
                  page={bbPage}
                  onDismiss={onDismissBBPageDialog}
                  texts={texts}
                />

                <UserPreferencesDialog
                  unitSystems={userUnitSystems}
                  preferences={preferences}
                  hidden={isUserPreferencesDialogHidden}
                  onDismiss={onDismissUserPreferencesDialog}
                  handleOnConfirm={onConfirmUserPreferencesDialog}
                  availLanguages={availLanguages}
                  texts={texts}
                  config={config}
                />

                <div className="mainBackground">
                  <Margin5 />
                  <div className={`SearchPanel__Root${searchVisible ? '_is-open' : ''}`}>
                    <IconButton
                      iconProps={{ iconName: 'Cancel' }}
                      onClick={closePanel}
                      styles={{ root: { position: 'absolute', right: 10 } }}
                      title="Close"
                    />
                  </div>
                  <div className="commandBarBackground" style={{ width: '100%', display: 'inline-block' }}>
                    <CommandBar
                      styles={{
                        secondarySet: {
                          display: 'flex',
                          alignItems: 'center',
                        },
                        root: {
                          height: '44px',
                        },
                      }}
                      style={{
                        width: `calc(100% - ${mbaseLinkWidth > 0 ? mbaseLinkWidth + 50 + 'px' : '280px'})`,
                        float: 'left',
                        verticalAlign: 'middle',
                      }}
                      {...commandBarProps}
                    />
                    <MBaseLink
                      appContent={appContent}
                      linkRef={mbaseLinkRef}
                      canAccess={canAccess}
                      config={config}
                      handleUpgradeClick={handleUpgradeClick}
                      texts={texts}
                      uicheckRun={uicheckRun}
                    />
                  </div>
                  <div className="toolbarBackground" style={{ display: 'flex', width: '100%', alignItems: 'center', flexWrap: 'wrap' }}>
                    <div>{filterButtons}</div>
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        marginLeft: 'auto',
                        justifyContent: 'flex-end',
                        paddingLeft: '10px',
                        paddingRight: '10px',
                        flexWrap: 'wrap',
                      }}
                    >
                      {toolbar}
                    </div>
                  </div>
                </div>
                <div className="mainScrollableBackground" style={{ position: 'relative', height: '100%', width: '100%' }}>
                  <ScrollablePane
                    data-testid="main-scrollable-pane"
                    componentRef={(ref) => {
                      setScrollablePane(ref);
                    }}
                    styles={{
                      contentContainer: {
                        display: 'flex',
                        flexDirection: 'column',
                        overflowX: 'auto',
                      },
                    }}
                  >
                    <Margin5 />
                    {scrollableContentRef && (
                      <InfiniteScroll
                        dataLength={dataLength}
                        fetchMoreData={fetchMoreData}
                        hasMore={hasMore}
                        scrollableTarget={scrollableContentRef}
                        isFetching={roots.status !== 'SUCCESS'}
                        texts={texts}
                        firstLoad={firstLoad}
                      >
                        <div className="amdc-block" style={{ width: '100%', height: '100%' }}>
                          {entityViewComponent}
                        </div>
                        {roots.status === 'SUCCESS' && items.length === 0 && (
                          <span
                            style={{
                              display: 'flex',
                              width: '100%',
                              height: '100%',
                              flexDirection: 'column',
                              justifyContent: 'center',
                              alignItems: 'center',
                              fontSize: '18px',
                            }}
                          >
                            {TextMapping.getUIText(TextMapping.UI_TEXT_NO_MATERIALS_FOUND, texts)}
                          </span>
                        )}
                        {contextMenuProps && <ContextualMenu {...contextMenuProps} />}
                      </InfiniteScroll>
                    )}
                  </ScrollablePane>
                </div>
                <ImportModal
                  providerCdefs={providerCdefs}
                  providers={providers}
                  isOpen={isImportModalOpened}
                  onDismiss={onModalDismiss}
                  onPrimaryClick={onImportSave}
                  onProviderChange={onProviderChange}
                  onSourceTemplateChange={onSourceTemplateChange}
                  onFileChange={onFileChange}
                />
                <ExportModal
                  unitSystems={unitSystems}
                  isOpen={isExportModalOpened}
                  onDismiss={onExportModalDismiss}
                  onPrimaryClick={onExportClick}
                  onUnitSystemChange={onUnitSystemChange}
                  onFlagChange={onFlagChange}
                />
              </Main>
              <Panel
                addNavigation={addNavigation}
                detailsResize={detailsResize}
                notifyResizeStart={notifyDetailsResizeStart}
                notifyResizeStop={notifyDetailsResizeStop}
                notifyFullScreen={notifyDetailsFullScreen}
                isOpen={detailsVisible}
                onDismiss={closePanel}
                title={panelTitle}
                loading={datasheetStatus !== 'SUCCESS'}
              >
                <div style={{ position: 'relative', height: '100%' }}>
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      height: '100%',
                      width: '100%',
                      position: 'absolute',
                    }}
                  >
                    {materialPropertiesTable}
                  </div>
                </div>
              </Panel>
              <Panel
                detailsResize={compareResize}
                notifyResizeStart={notifyCompareResizeStart}
                notifyResizeStop={notifyCompareResizeStop}
                notifyFullScreen={notifyCompareFullScreen}
                isOpen={compareVisible}
                onDismiss={closePanel}
                title={compareTitle}
              >
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    position: 'relative',
                    height: 'calc(100vh - 135px)',
                  }}
                >
                  {roots.perms && roots.perms.includes(comparePermission) && (
                    <ContentDetails activeTab={activeCompareTab} onTabClick={onCompareTabClick}>
                      <Tab name={TextMapping.getUIText(TextMapping.UI_TEXT_DATA, texts)} data-testid="compare-data-tab">
                        <div style={{ flex: 'auto', zIndex: '10' }}>
                          <ComparisonTable
                            data-testid="comparison-table"
                            catalogs={compareCatalog}
                            compareLayout={compareLayout}
                            compareFullScreen={compareFullScreen}
                            selectedEntities={compareDetails}
                            contentDefinitions={contentDefinitions}
                            showProviderHierarchy={showProviderHierarchy}
                            tags={tags}
                            texts={texts}
                            setLocalCompareSelected={setLocalCompareSelected}
                            exportExcel={exportExcel}
                            selectionLimit={selectionLimit}
                            showLimitSelectionDialog={showLimitSelectionDialog}
                          />
                        </div>
                      </Tab>
                      {hasDiagrams && (
                        <Tab name={TextMapping.getUIText(TextMapping.UI_TEXT_CHART, texts)} data-testid="compare-chart-tab">
                          <PlotComparison
                            data-testid="plot-comparison"
                            advancedSearchResize={compareResize}
                            name="plotComparison"
                            fullScreen={compareFullScreen}
                            selectedEntities={localCompareSelected}
                            contentDefinitions={contentDefinitions}
                            catalogs={compareCatalog}
                            downloadPDF={downloadPDF}
                            texts={texts}
                            setDiagramsAvailable={setDiagramsAvailable}
                          />
                        </Tab>
                      )}
                      {hasDiagrams && (
                        <Tab
                          isVisible={diagramsAvailable}
                          name={TextMapping.getUIText(TextMapping.UI_TEXT_OVERLAY, texts)}
                          data-testid="compare-overlay-tab"
                        >
                          <CompareOverlay
                            catalogs={compareCatalog}
                            selectedEntities={localCompareSelected}
                            texts={texts}
                            diagramsAvailable={diagramsAvailable}
                          />
                        </Tab>
                      )}
                      {roots.perms.includes('ui.spiderplot') && (
                        <Tab name={TextMapping.getUIText(TextMapping.UI_TEXT_POLAR_CHART, texts)} data-testid="compare-polar-chart-tab">
                          <PropertySelectionTree
                            data-testid="polar-comparison"
                            polarCatalog={polarCatalog}
                            preferences={preferences}
                            texts={texts}
                            selectedEntities={localCompareSelected}
                            getPolarData={getPolarData}
                            polarData={polarData}
                            fullScreen={compareFullScreen}
                            polarLoadingStatus={polarLoadingStatus}
                          ></PropertySelectionTree>
                        </Tab>
                      )}
                    </ContentDetails>
                  )}
                  {!roots.perms.includes(comparePermission) && (
                    <BBPageComponent
                      page={compareRestrictedPage}
                      bbPageForComponent={bbPageForComponent}
                      getBBPageForComponent={getBBPageForComponent}
                      appContent={appContent}
                      language={language}
                      texts={texts}
                    />
                  )}
                </div>
              </Panel>
              <Panel
                isOpen={advancedSearchVisible}
                onDismiss={closePanel}
                title={TextMapping.getUIText(TextMapping.UI_TEXT_ADVANCED_SEARCH, texts)}
                notifyResizeStart={notifyAdvancedSearchResizeStart}
                notifyResizeStop={notifyAdvancedSearchResizeStop}
                notifyFullScreen={notifyAdvancedSearchFullScreen}
              >
                <AdvancedSearch
                  searchCatalog={searchCatalog}
                  ranges={advSearchRanges}
                  fetchSearch={fetchSearch}
                  qs={qs}
                  newSortColumn={sortColumn}
                  newSortDirection={sortDirection}
                  updateLoadIndex={updateLoadIndex}
                  updateOffset={updateOffset}
                  cleanEntityItems={cleanEntityItems}
                  appContent={appContent}
                  texts={texts}
                  history={history}
                  updateBreadcrumbs={updateBreadcrumbs}
                  replaceFilterData={replaceFilterData}
                  filterDataStatus={filterDataStatus}
                  clearSearch={clearSearch}
                  scatterUpdatedRanges={scatterUpdatedRanges}
                />
              </Panel>
              {roots.perms.includes(scatterPlotPermission) && (
                <Panel
                  isOpen={scatterPlotVisible}
                  onDismiss={closePanel}
                  title={TextMapping.getUIText(TextMapping.UI_TEXT_SCATTER_PLOT, texts)}
                >
                  <ScatterPlot
                    qs={qs}
                    texts={texts}
                    searchCatalog={scatterCatalog}
                    ranges={advSearchRanges}
                    getScatterData={getScatterData}
                    scatterData={scatterData}
                    downloadPDF={downloadPDF}
                    scatterCBforRanges={scatterCBforRanges}
                  />
                </Panel>
              )}
              {!roots.perms.includes(scatterPlotPermission) && (
                <Panel
                  isOpen={scatterPlotVisible}
                  onDismiss={closePanel}
                  title={TextMapping.getUIText(TextMapping.UI_TEXT_SCATTER_PLOT, texts)}
                >
                  <div
                    className="amdc-block"
                    style={{
                      height: 'calc(100vh - 135px)',
                      width: '100%',
                      overflow: 'hidden',
                      display: 'flex',
                      flexDirection: 'column',
                    }}
                  >
                    <BBPageComponent
                      page={scatterPlotRestrictedPage}
                      bbPageForComponent={bbPageForComponent}
                      getBBPageForComponent={getBBPageForComponent}
                      appContent={appContent}
                      language={language}
                      texts={texts}
                    />
                  </div>
                </Panel>
              )}
              <LCAGeneric
                appContent={appContent}
                config={config}
                texts={texts}
                canAccess={canAccess}
                onDownloadClick={onDownloadClick}
                handleContactUsClick={handleContactUsClick}
                webSocket={webSocket}
                lcaGeneric={lcaGeneric}
                getLCAGeneric={getLCAGeneric}
                language={language}
                co2CalcVisible={co2CalcVisible}
                closeCo2Calc={closeCo2Calc}
              />

              <CustomizeTablePanel
                tableColumns={tableColumns}
                hidden={isCustomizePanelHidden}
                onDismiss={onDismissCustomizeTablePanel}
                handleOnConfirm={onConfirmCustomizeTablePanel}
                preferences={preferences}
                texts={texts}
                appContent={appContent}
                ranges={ranges}
                rangeStatus={rangeStatus}
              />
              <Panels activePanelId={panelId} onDismiss={handlePanelDismiss} />

              <LocalNotifications notifications={localNotification} hideLocalNotification={hideLocalNotification}></LocalNotifications>
              <SaveCriteriaAsDialog
                title={'Save Favorite List'}
                criteriaNames={savedFavoriteNames}
                hidden={isSaveFavoritesAsDialogHidden}
                onDismiss={() => setIsSaveFavoritesAsDialogHidden(true)}
                onSave={(name) => handleSaveFavoritesAs(name)}
                texts={texts}
              />
              <SaveCriteriaDialog
                hidden={isSaveFavoritesDialogHidden}
                onDismiss={() => setIsSaveFavoritesDialogHidden(true)}
                onSave={handleSaveFavorites}
                texts={texts}
              />
              <LoadCriteriaDialog
                title={'Load Favorites'}
                criterias={savedFavorites}
                hidden={isLoadFavoritesDialogHidden}
                onDelete={handleDeleteFavorites}
                onDismiss={() => setIsLoadFavoritesDialogHidden(true)}
                onLoad={handleLoadFavorites}
                texts={texts}
              />
              <div
                style={{
                  position: 'fixed',
                  bottom: config?.scrolltotop?.bottom ? config.scrolltotop.bottom : '75px',
                  right: config?.scrolltotop?.right ? config.scrolltotop.right : '50px',
                  display: detailsVisible ? 'none' : 'block',
                }}
              >
                <RoundIconButton
                  id="scroll-top-button"
                  style={{ display: 'none', opacity: config?.scrolltotop?.opacity ? config.scrolltotop.opacity : 0.8 }}
                  iconProps={{ iconName: config?.scrolltotop?.iconName ? config.scrolltotop.iconName : 'Up' }}
                  onClick={() => {
                    scrollableContentRef.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
                  }}
                />
              </div>
            </Content>
          </Wrap>
        </>
      );
    } else {
      let view = currentPage;
      if (qs.current && qs.current.view) {
        view = qs.current.view;
      }

      if (appContent && appContent.success === false) {
        if (appContent.redirect) {
          window.location.href = appContent.redirect;
        } else {
          view = '404';
        }
      }

      body = (
        <>
          <Helmet>
            {config?.enableUsercentrics && (
              <script>
                {`window.UC_UI_DOMAINS = {
                              crossDomainConsentSharingIFrame: '${window.location.origin}/cross-domain-bridge.html',
                            }
                            `}
              </script>
            )}

            {config?.enableUsercentrics && (
              <script
                id="usercentrics-cmp"
                src="https://app.usercentrics.eu/browser-ui/latest/loader.js"
                data-settings-id={config.enableUsercentrics.id}
                async
              ></script>
            )}

            {/* Google Tag Manager  */}
            {config?.enableGoogleAnalytics && (
              <script>
                {`
                          (function(w, d, s, l, i) {
                              w[l] = w[l] || [];
                              w[l].push({ 'gtm.start': new Date().getTime(), event: 'gtm.js' });
                              var f = d.getElementsByTagName(s)[0],
                                j = d.createElement(s),
                                dl = l != 'dataLayer' ? '&l=' + l : '';
                              j.async = true;
                              j.src = 'https://www.googletagmanager.com/gtm.js?id=' + i + dl;
                              f.parentNode.insertBefore(j, f);
                            })(window, document, 'script', 'dataLayer', '${config.enableGoogleAnalytics.token}');
                          `}
              </script>
            )}
            {/* End Google Tag Manager */}
            {/* Start of HubSpot Embed Code */}
            {config?.enableHubSpot && (
              <script type="text/javascript" id="hs-script-loader" async defer src="//js.hs-scripts.com/47251.js"></script>
            )}
            {/* End of HubSpot Embed Code */}
          </Helmet>
          <Landing
            qs={qs}
            history={history}
            preferences={preferences}
            texts={texts}
            canAccess={canAccess}
            onDownloadClick={onDownloadClick}
            handleContactUsClick={handleContactUsClick}
            webSocket={webSocket}
            executeSearch={executeLandingSearch}
            updateQuickSearch={updateQuickSearch}
            quickSearchValue={quickSearchValue}
            landingBB={landingBB}
            getLandingBuildingBlocks={getLandingBuildingBlocks}
            appContent={appContent}
            language={language}
            view={view}
            handleWaffleMenuClick={handleWaffleMenuClick}
            handleUserButtonClick={handleUserButtonClick}
            handleNotificationBellClick={handleNotificationBellClick}
            panelId={panelId}
            handlePanelDismiss={handlePanelDismiss}
          />
          <BBPageDialog
            api={api}
            hidden={isBBPageDialogHidden}
            bbPageForDialog={bbPageForDialog}
            clearBBPageForDialog={clearBBPageForDialog}
            getBBPageForDialog={getBBPageForDialog}
            page={bbPage}
            onDismiss={onDismissBBPageDialog}
            texts={texts}
          />
        </>
      );
    }
  }
  return body;
}
export default connect(mapStateToProps, mapDispatchToProps)(withTopbar(View));
